import { setTermsAndConditionError } from "../../store/slices/errors";
import apiClient from "../../utils/api.client";
import { getOrganizationId } from "../../utils/session.utils";

// NOTE: Add orgId to all EPs, assess whether the payload contains the orgId or not
export async function getAllTermsAndConditions(
  page = 1,
  perPage = 10,
  dispatch,
  organizationId = getOrganizationId(),
) {
  const URL = `/api-terms/terms?pageNumber=${page}&pageSize=${perPage}&orderCriteria=ASC&orderField=title&organizationId=${organizationId}`;

  try {
    const response = await apiClient.get(URL);

    return response.data;
  } catch (error) {
    console.error("Error at getAllTermAndConditions EP: ", error);
    throw new Error(error);
  }
}

//payload contiene organizationId
export async function createTermsAndCondition(payload) {
  try {
    const response = await apiClient.post(`/api-terms/terms`, payload);
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at createTermsAndCondition EP: ", error);
    throw error;
  }
}

//payload contiene organizationId
export async function updateTermsAndCondition(payload) {
  try {
    const response = await apiClient.put(`/api-terms/terms`, payload);
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at updateTermsAndCondition EP: ", error);
    throw error;
  }
}

export async function deleteTermsAndCondition(
  TermsAndConditionId,
  organizationId = getOrganizationId(),
) {
  try {
    const response = await apiClient.delete(
      `/api-terms/terms/${TermsAndConditionId}?organizationId=${organizationId}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at deleteTermsAndConditions EP: ", error);
    throw error;
  }
}
