// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Collapse_box__CHwHC {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 40px;
  margin-top: 20px;
  border-radius: 20px;
}

.Collapse_agencies__XFAWA {
  display: flex;
  align-items: center;
}

.Collapse_chips__SQXEA {
  display: flex;
  width: 90%;
  margin-top: 18px;
  margin-left: 23px;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Agencias/Collapse/Collapse.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".box {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  font-size: 40px;\n  margin-top: 20px;\n  border-radius: 20px;\n}\n\n.agencies {\n  display: flex;\n  align-items: center;\n}\n\n.chips {\n  display: flex;\n  width: 90%;\n  margin-top: 18px;\n  margin-left: 23px;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `Collapse_box__CHwHC`,
	"agencies": `Collapse_agencies__XFAWA`,
	"chips": `Collapse_chips__SQXEA`
};
export default ___CSS_LOADER_EXPORT___;
