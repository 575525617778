import { ComponentError } from "../../ErrorPages/ComponentsError";
import React from "react";
import Loader from "../Loader";

const HandleRequests = ({ children, error, loading }) => {
  return (
    <>
      {error.error ? (
        <ComponentError code={error?.code} />
      ) : (
        <>{loading ? <Loader /> : children}</>
      )}
    </>
  );
};

export default HandleRequests;
