// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addTerms_jsx_container__NzuhE {
  width: 100%;
}

.addTerms_jsx_container__NzuhE .addTerms_jsx_titleContainer__EFp1D {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addTerms_jsx_container__NzuhE .addTerms_jsx_titleContainer__EFp1D .addTerms_jsx_title__8I8Nf {
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.addTerms_jsx_container__NzuhE .addTerms_jsx_titleContainer__EFp1D .addTerms_jsx_title__8I8Nf h1 {
  width: auto;
  font-size: 1.8rem;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  margin-left: 10px;
}

.addTerms_jsx_container__NzuhE .addTerms_jsx_btn__HpCQ3 {
  border-bottom: 3px;
  width: 140px;
  height: 40px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/TermsAndConditions/addTerms.jsx/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,+BAA+B;EAC/B,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".container {\n  width: 100%;\n}\n\n.container .titleContainer {\n  margin-top: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.container .titleContainer .title {\n  width: 55%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 20px;\n}\n\n.container .titleContainer .title h1 {\n  width: auto;\n  font-size: 1.8rem;\n  font-weight: 300;\n  font-family: Roboto, sans-serif;\n  margin-left: 10px;\n}\n\n.container .btn {\n  border-bottom: 3px;\n  width: 140px;\n  height: 40px;\n  transition: 0.3s;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `addTerms_jsx_container__NzuhE`,
	"titleContainer": `addTerms_jsx_titleContainer__EFp1D`,
	"title": `addTerms_jsx_title__8I8Nf`,
	"btn": `addTerms_jsx_btn__HpCQ3`
};
export default ___CSS_LOADER_EXPORT___;
