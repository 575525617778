// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerMobile_container__B4zsX {
  transition: 0.5s;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 100%;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.286);
  z-index: 30;
  top: 0;
}

.headerMobile_container__B4zsX .headerMobile_burger__SCKC\\+ {
  transition: 0.3s;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerMobile_container__B4zsX .headerMobile_burger__SCKC\\+:hover {
  transition: 0.3s;
  cursor: pointer;
  filter: brightness(1.3);
}

.headerMobile_container__B4zsX .headerMobile_logo__kg4YC {
  position: relative;
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerMobile_container__B4zsX .headerMobile_logo__kg4YC .headerMobile_headerLogo__vSPUX {
  height: 100%;
  object-fit: cover;
}

.headerMobile_container__B4zsX .headerMobile_flags__U3GlT {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerMobile_container__B4zsX .headerMobile_flagIcon__hHsnC {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/HeaderMobile/headerMobile.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,4BAA4B;EAC5B,iDAAiD;EACjD,WAAW;EACX,MAAM;AACR;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\n  transition: 0.5s;\n  display: flex;\n  position: fixed;\n  justify-content: center;\n  align-items: center;\n  height: 10vh;\n  width: 100%;\n  border-radius: 0 0 20px 20px;\n  box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.286);\n  z-index: 30;\n  top: 0;\n}\n\n.container .burger {\n  transition: 0.3s;\n  width: 20%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.container .burger:hover {\n  transition: 0.3s;\n  cursor: pointer;\n  filter: brightness(1.3);\n}\n\n.container .logo {\n  position: relative;\n  width: 60%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.container .logo .headerLogo {\n  height: 100%;\n  object-fit: cover;\n}\n\n.container .flags {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.container .flagIcon {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `headerMobile_container__B4zsX`,
	"burger": `headerMobile_burger__SCKC+`,
	"logo": `headerMobile_logo__kg4YC`,
	"headerLogo": `headerMobile_headerLogo__vSPUX`,
	"flags": `headerMobile_flags__U3GlT`,
	"flagIcon": `headerMobile_flagIcon__hHsnC`
};
export default ___CSS_LOADER_EXPORT___;
