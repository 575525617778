export const calcularDiferenciaFechas = (fecha1, fecha2) => {
  console.log("Fechas que me llegan: ", fecha1, fecha2);
  // Convertir las cadenas de fecha en objetos Date
  const fecha1Arr = fecha1.split("-").reverse();
  console.log("fecha1Arr ", fecha1Arr);
  const fecha1Obj = new Date(fecha1Arr[2], fecha1Arr[1] - 1, fecha1Arr[0]);
  const fecha2Arr = fecha2.split("-").reverse();
  console.log("fecha2Arr ", fecha2Arr);
  const fecha2Obj = new Date(fecha2Arr[2], fecha2Arr[1] - 1, fecha2Arr[0]);

  // Calcular la diferencia en milisegundos
  const diferenciaMs = Math.abs(fecha2Obj - fecha1Obj);

  // Convertir la diferencia a días
  const dias = Math.ceil(diferenciaMs / (1000 * 60 * 60 * 24));
  console.log("Diferencia: ", dias);

  return dias;
};
