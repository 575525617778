import { parse } from "date-fns";
import format from "date-fns/format";
import ConfigsSearcher from "../config/Configs";

export const convertDate = (date) => {
  const slicedDate = date?.slice(0, 10);
  const splitedDate = slicedDate?.split("-");
  const convertedDate =
    splitedDate && `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
  return convertedDate;
};

export const parseDate = (date) => {
  const slicedDate = date?.slice(0, 10);
  const splitedDate = slicedDate?.split("-");
  const convertedDate =
    splitedDate && `${splitedDate[0]}-${splitedDate[1]}-${splitedDate[2]}`;
  return convertedDate;
};

export const configDate = () => {
  const config = ConfigsSearcher();
  const locale = config.configFinder("DATE_FORMAT");
  const localeTime = config.configFinder("DATETIME_FORMAT");

  const formatLocalDate = (value, formatDate) => {
    if (value != null && value != "" && value != "-") {
      try {
        const date = parse(value, formatDate, new Date());
        const formattedDate = format(date, locale);
        return formattedDate;
      } catch (err) {
        return "-";
      }
    }
    return "-";
  };

  const formatLocalDateTime = (value, formatDate, formatTime) => {
    try {
      const timeDate = value.split("T");
      console.log("TimeDate", timeDate);

      if (timeDate.length > 1) {
        console.log("tiemdata", timeDate);
        const date = parse(timeDate[0], formatDate, new Date());
        const time = parse(timeDate[1], formatTime, new Date());
        const formattedDate = format(date, locale);
        const formattedTime = format(time, localeTime);
        const returnDate = formattedDate + " " + formattedTime.split(" ")[1];
        return returnDate;
      } else {
        const timeDate = value.split(" ");
        if (timeDate.length > 1) {
          const date = parse(timeDate[0], formatDate, new Date());
          const time = parse(timeDate[1], formatTime, new Date());
          const formattedDate = format(date, locale);
          const formattedTime = format(time, localeTime);
          const returnDate = `${formattedDate} ${formattedTime.split(" ")[1]}`;
          return returnDate;
        } else {
          return "-";
        }
      }
    } catch (err) {
      return "-";
    }
  };

  return { formatLocalDate, formatLocalDateTime };
};

// export const LocalDate = ({ value, format }) => {
//   return (
//     <p style={{ margin: 0 }}>{formatLocalDate(value, format)}</p>
//   )
// }

// export const LocalDateTime = ({ value, format, formatTime }) => {
//   return (
//     <p style={{ margin: 0 }}>{formatLocalDateTime(value, format, formatTime)}</p>
//   )
// }
