// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chips_chipContainer__ykK2x {
  width: 15%;
  min-width: 5rem;
  margin-left: 10px;
}

.Chips_noChips__i7tTY {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Agencias/Chips/Chips.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".chipContainer {\n  width: 15%;\n  min-width: 5rem;\n  margin-left: 10px;\n}\n\n.noChips {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chipContainer": `Chips_chipContainer__ykK2x`,
	"noChips": `Chips_noChips__i7tTY`
};
export default ___CSS_LOADER_EXPORT___;
