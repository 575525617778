import apiClient from "../../utils/api.client";
import { getSaaId } from "../../utils/session.utils";

export const getOnLine = async (
  organizationId,
  desde,
  hasta,
  agencia,
  subagencia,
) => {
  try {
    const saaId = getSaaId();
    const response = await apiClient.get(
      `/saaServices/saaServices/services/abaco/detalleMovimientosOnline?organizacionId=${saaId}&fechadesde=${desde}&fechahasta=${hasta}&agencia=${agencia}&subagencia=${subagencia}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at getOnLine EP: ", error);
    throw error;
  }
};
