// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
#root {
  background-color: #fafafa;
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,yBAAyB;EACzB,SAAS;EACT,UAAU;EACV,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":["body,\n#root {\n  background-color: #fafafa;\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
