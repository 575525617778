// import react
import React, { createContext, useContext, useEffect, useState } from "react";
// import redux
import { useSelector } from "react-redux";
// import jwt
import { decodeToken, useJwt } from "react-jwt";

// ========================== | AUTH PROVIDER | ===============================

const AuthContext = createContext();

export default function AuthProvider({ children }) {
  // Section: Redux
  const sessionRedux = useSelector((state) => state.session);
  // Section: State
  const [session, setSession] = useState(sessionRedux);
  const [userId, setUserId] = useState(null);

  // const { decodedToken } = useJwt(session.accessToken)
  //
  // if (decodedToken) {
  //   const currentExpireTime = decodedToken.exp
  //   const expireDate = new Date(currentExpireTime * 1000);
  //   console.log('Token expires: ', expireDate)
  // }

  // Section: Effect
  useEffect(() => {
    setSession(sessionRedux);
  }, [sessionRedux]);

  useEffect(() => {
    if (session) {
      if (session.accessToken) {
        const decodedToken = decodeToken(session.accessToken);
        setUserId(decodedToken.sub);
      }
    }
  }, [session]);

  // Section: JSX
  return (
    <AuthContext.Provider value={{ session, userId, setSession }}>
      {children}
    </AuthContext.Provider>
  );
}

/* Exporting hook: UseAuth
 * Through this hook the session can be access from the AuthProvider's children
 * */
export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
