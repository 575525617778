import authService from "../services/auth.service";
import { resetLocalSlice } from "../store/slices/localSlice";
import { resetAgenciesSlice } from "../store/slices/my_agencies";
import { resetPaginationSlice } from "../store/slices/paginationSlice";
import { resetErrors } from "../store/slices/errors";
import { resetPointsSlice } from "../store/slices/pointsSlice";
import { resetUserSlice } from "../store/slices/userSlice";
import { deleteSession } from "../store/slices/session";
import { resetGammaSlice, resetLoggedUser } from "../store/slices/gammaSlice";
import store from "../store";

// Section: Function
export function isSessionExpired(expirationTime) {
  const currentTime = Math.floor(Date.now() / 1000);

  if (currentTime >= expirationTime) {
    return true;
  } else {
    return false;
  }
}

export async function logOutSession(store) {
  try {
    await authService
      .logOut()
      .then(() => {
        console.log("Log from interceptor logout");
        store.dispatch(resetLocalSlice());
        store.dispatch(resetAgenciesSlice());
        store.dispatch(resetPaginationSlice());
        store.dispatch(resetErrors());
        store.dispatch(resetPointsSlice());
        store.dispatch(resetUserSlice());
        store.dispatch(deleteSession());
        store.dispatch(resetGammaSlice());
        store.dispatch(resetLoggedUser());
      })
      .catch((error) => console.log(error)); // login out session
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Gets the organization Id from the redux storage
 *
 * @returns {number} organizationId - current organization id stored in the reux storage
 **/
export const getOrganizationId = () =>
  store.getState().gamma.activeOrganization.id;

/**
 * Gets the saa Id from the redux storage
 *
 * @returns {number} organizationId - current saa id stored in the reux storage
 **/
export const getSaaId = () => store.getState().gamma.organizationsPackage.saaId;
