// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServerError_container__j4uoz {
  width: 100%;
  height: 86vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

.ServerError_title__KFjPX {
  font-family: sans-serif;
  font-weight: 500;
  font-size: 3em;
}

.ServerError_title500__O6oZJ {
  font-family: sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 3em;
  margin-left: 30px;
  margin-right: 30px;
}

.ServerError_container_error__1gjrX {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ServerError_errorImage__tUjvL {
  width: 20vw;
  margin-top: 10vw;
}

.ServerError_errorCode__cuesJ {
  font-size: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorPages/ServerError/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".container {\n  width: 100%;\n  height: 86vh;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.title {\n  font-family: sans-serif;\n  font-weight: 500;\n  font-size: 3em;\n}\n\n.title500 {\n  font-family: sans-serif;\n  font-weight: 500;\n  text-align: center;\n  font-size: 3em;\n  margin-left: 30px;\n  margin-right: 30px;\n}\n\n.container_error {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.errorImage {\n  width: 20vw;\n  margin-top: 10vw;\n}\n\n.errorCode {\n  font-size: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ServerError_container__j4uoz`,
	"title": `ServerError_title__KFjPX`,
	"title500": `ServerError_title500__O6oZJ`,
	"container_error": `ServerError_container_error__1gjrX`,
	"errorImage": `ServerError_errorImage__tUjvL`,
	"errorCode": `ServerError_errorCode__cuesJ`
};
export default ___CSS_LOADER_EXPORT___;
