import React, { useEffect } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import styles from "./SearchBar.module.css";
import { useSelector } from "react-redux";
import { getAllConfigurations, searchConfigItem } from "../../../api/config/ConfigService";
import { useState } from "react";
import { searchUser } from "../../../api/users/UsersConfig";
import { agencieSearch } from "../../../api/agencies/AgenciesService";
import { t } from "i18next";

export const SearchBar = (props) => {
  const {
    keys,
    result,
    onSelect,
    primaryColor,
    format,
    placeholder,
    component,
  } = props;
  const { activeOrganization } = useSelector((state) => state.gamma);
  const [inputResult, setinputResult] = useState("");
  const [inputSearch, setinputSearch] = useState("");

  useEffect(() => {
    switch (component) {
      case "users":
        searchUser(inputSearch).then((res) => {
          setinputResult(res?.content);
        });

        break;
      case "sale_points":
        agencieSearch(activeOrganization?.id, inputSearch).then((res) => {
          setinputResult(res?.content);
          console.log("respuesta de busqueda de puntos de venta", res.content);
        });
        break;
      case "configuration":
        if (inputSearch != ""){
        searchConfigItem(inputSearch)
          .then((res)=> {
            setinputResult(res?.content);
          })
        }
        else {
          getAllConfigurations().then((res)=>{
            setinputResult(res?.content);
          })
        }
        break;
      default:
        setinputResult("");
    }
  }, [inputSearch, activeOrganization, component]);

  return (
    <div className={styles.searchBarContainer}>
      <ReactSearchAutocomplete 
        items={inputResult}
        inputDebounce={1000}
        onSearch={(e) => setinputSearch(e)}
        fuseOptions={keys}
        resultStringKeyName={result}
        placeholder={placeholder}
        onSelect={onSelect}
        formatResult={format}
        styling={{
          height: "3rem",
          fontSize: "1rem",
          fontFamily: "Roboto, sans-serif",
          border: `2px solid ${primaryColor}`,
          borderRadius: "10px",
          hoverBackgroundColor: primaryColor,
          zIndex: 20,
          transition: "0.5s",
        }}
      />
    </div>
  );
};
