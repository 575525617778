import { createSlice } from "@reduxjs/toolkit";
import { BASE_THEME, languages, itemDatas } from "../../config/strings";
import { today } from "../../helpers/today";

const colorBase = itemDatas.find((theme) => theme.title === BASE_THEME);
const initialUi = {
  title: colorBase.title,
  primary: {
    main: colorBase.primary.main,
    light: colorBase.primary.light,
    dark: colorBase.primary.dark,
  },
  secondary: {
    main: colorBase.secondary.main,
    light: colorBase.secondary.light,
    dark: colorBase.secondary.dark,
  },
  custom: [],
};

const initialState = {
  uiConfig: initialUi,
  profilePicture: "",
  logos: {},
  appLogos: {},
  currentLogo: {},
  legalTerms: {},
  location: "",
  terms_And_Conditions: {},
  textColor: "black",
  language: languages.es,
  favAgencies: {},
  configKeys: [],
  filterPremios: {
    from: today(),
    to: today(),
    fromOthers: false,
    byOthers: false,
  },
  PrizesRes: {},
};

const localSlice = createSlice({
  name: "apiCallsLocal",
  initialState,
  reducers: {
    uiConfigUpdate(state, action) {
      state.uiConfig = action.payload;
    },
    uiProfilePictureUpdate(state, action) {
      state.profilePicture = action.payload;
    },
    uiOrganizationLogo(state, action) {
      state.currentLogo = action.payload;
    },
    uiLogosUpdate(state, action) {
      state.logos = action.payload;
    },
    legalTermsUpdate(state, action) {
      state.legalTerms = action.payload;
    },
    legalTermsReset(state, action) {
      state.legalTerms = {};
    },
    loginTermsAccepted(state, action) {
      state.legalTerms.accepted = true;
    },
    termsAndConditionsUpdate(state, action) {
      state.terms_And_Conditions = action.payload;
    },
    increaseTermsAcceptRetries(state, action) {
      state.legalTerms.postponeTimes += 1;
    },
    addPalette(state, action) {
      state.uiConfig = {
        ...state.uiConfig,
        custom: [...state.uiConfig.custom, action.payload],
      };
    },
    addLogos(state, action) {
      state.logos = [...state.logos, action.payload];
    },
    deletePalette(state, action) {
      state.uiConfig.custom = action.payload;
    },
    deleteLogos(state, action) {
      state.logos = action.payload;
    },
    appLogosUpdate(state, action) {
      state.appLogos = action.payload;
    },
    addAppLogos(state, action) {
      state.appLogos = [...state.appLogos, action.payload];
    },
    deleteAppLogos(state, action) {
      state.appLogos = action.payload;
    },
    updateTextColor(state, action) {
      state.textColor = action.payload;
    },
    updateLocation(state, action) {
      state.location = action.payload;
    },
    updateLanguage(state, action) {
      state.language = action.payload;
    },
    favAgenciesUpdate(state, action) {
      state.favAgencies = action.payload;
    },
    resetLocalSlice(state, action) {
      state.uiConfig = initialUi;
      state.profilePicture = "";
      state.logos = {};
      state.appLogos = {};
      state.currentLogo = {};
      state.legalTerms = {};
      state.terms_And_Conditions = {};
      state.textColor = "black";
      state.language = languages.es;
      state.favAgencies = {};
      state.configKeys = [];
    },
    resetLogos(state, action) {
      state.appLogos = {};
    },
    configKeysUpdate(state, action) {
      state.configKeys = action.payload;
    },
    updateFilter(state, action) {
      state.filterPremios = action.payload;
    },
    updatePrizesRes(state, action) {
      state.filterPremios = action.payload;
    },
  },
});

export const {
  uiConfigUpdate,
  legalTermsUpdate,
  legalTermsReset,
  loginTermsAccepted,
  increaseTermsAcceptRetries,
  uiProfilePictureUpdate,
  uiLogosUpdate,
  termsAndConditionsUpdate,
  addPalette,
  updateTextColor,
  updateLocation,
  deletePalette,
  addLogos,
  deleteLogos,
  updateLanguage,
  favAgenciesUpdate,
  appLogosUpdate,
  addAppLogos,
  deleteAppLogos,
  uiOrganizationLogo,
  resetLocalSlice,
  resetLogos,
  configKeysUpdate,
  updateFilter,
  updatePrizesRes,
} = localSlice.actions;

export default localSlice.reducer;
