import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  concentratorsError: {},
  concentratorSelectedError: {},
  agencieSelectedError: {},
  termsAndConditionError: { trys: 0 },
  my_agenciesError: {},
  usersError: {},
};

const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setConcentratorsError(state, action) {
      state.concentratorsError = {
        error: action.payload.error,
        code: action.payload.code,
      };
    },
    setConcentratorSelectedError(state, action) {
      state.concentratorSelectedError = {
        error: action.payload.error,
        code: action.payload.code,
      };
    },
    setAgencieSelectedError(state, action) {
      state.agencieSelectedError = {
        error: action.payload.error,
        code: action.payload.code,
      };
    },
    setTermsAndConditionError(state, action) {
      state.termsAndConditionError = {
        error: action.payload.error,
        code: action.payload.code,
        trys: action.payload.trys ? state.termsAndConditionError.trys + 1 : 0,
      };
    },
    setMy_AgenciesError(state, action) {
      state.my_agenciesError = {
        error: action.payload.error,
        code: action.payload.code,
      };
    },
    setUsersError(state, action) {
      state.usersError = {
        error: action.payload.error,
        code: action.payload.code,
      };
    },
    resetErrors(state, action) {
      state.concentratorsError = {};
      state.concentratorSelectedError = {};
      state.agencieSelectedError = {};
      state.termsAndConditionError = { trys: 0 };
      state.my_agenciesError = {};
      state.usersError = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setConcentratorsError,
  setConcentratorSelectedError,
  setAgencieSelectedError,
  setTermsAndConditionError,
  setMy_AgenciesError,
  setUsersError,
  resetErrors,
} = errorsSlice.actions;
export default errorsSlice.reducer;
