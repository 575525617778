import "./index.css";
import React from "react";

export const Ayuda = () => {
  return (
    <div className="container">
      <div className="indicator">
        <h1>Ayuda</h1>
      </div>
    </div>
  );
};
