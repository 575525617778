import React from "react";
import styles from "./index.module.css";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  increaseTermsAcceptRetries,
  loginTermsAccepted,
} from "../../../store/slices/localSlice";
import { redirect, useNavigate } from "react-router-dom";
import { ConvertColorText } from "../../../helpers/ConvertColorText";
import { useTranslation } from "react-i18next";
import { updateLegal } from "../../../api/local";
import { STATUS_OK } from "../../../config/strings";
import { useAuth } from "../../Auth/AuthProvider";
import { useJwt } from "react-jwt";

export const LoginTerms = () => {
  const { main } = useSelector((state) => state.local.uiConfig.primary);
  const { legalTerms } = useSelector((state) => state.local);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const acceptRetries = legalTerms.postponeTimes <= legalTerms.omitAmount;
  const { t } = useTranslation();
  const navigateToPanels = () => navigate(`../main/agencias`);
  const { session } = useAuth();
  const { decodedToken } = useJwt(session.accessToken);

  ConvertColorText(main);

  const acceptTerms = () => {
    const termsStatus = {
      termsConditionID: legalTerms.termsAndConditions.id,
      userUUID: decodedToken.sub,
      postponeTimes: legalTerms.postponeTimes,
      accepted: true,
      organizationId: legalTerms.organizationId,
    };
    updateLegal(termsStatus)
      .then(() => {
        dispatch(loginTermsAccepted());
        navigateToPanels();
      })
      .catch((error) => {
        console.log(error);
        navigate(`../server-error`);
      });
  };

  const omitTerms = () => {
    const termsStatus = {
      termsConditionID: legalTerms.termsAndConditions.id,
      userUUID: legalTerms.userUUID,
      postponeTimes: legalTerms.postponeTimes + 1,
      accepted: false,
      organizationId: legalTerms.organizationId,
    };
    updateLegal(termsStatus)
      .then((resp) => {
        dispatch(increaseTermsAcceptRetries());
        navigateToPanels();
      })
      .catch(() => {
        navigate(`../server-error`);
      });
  };

  const logOut = () => {
    navigate("/logout", { redirect: true });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t("terms")}</h1>
      <div className={styles.container_terminos}>
        <TextField
          sx={{ width: 800, bgcolor: "white" }}
          id="outlined-multiline-static"
          multiline
          rows={20}
          defaultValue={legalTerms.termsAndConditions.textTc}
          disabled
        />
        <Grid justifyContent="center" container>
          <Grid item xs={2}>
            <Button
              onClick={acceptTerms}
              variant="contained"
              sx={{ marginTop: 2 }}
            >
              {t("accept")}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={acceptRetries ? omitTerms : logOut}
              variant="outlined"
              sx={{ marginTop: 2 }}
            >
              {acceptRetries ? `${t("skip")}` : `${t("logOut")}`}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
