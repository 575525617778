// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_container__dKQex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.Header_container__dKQex .Header_headerLogo__bqkbi {
  margin-left: 30px;
  width: 15rem;
  max-height: 20rem;
  object-fit: contain;
}

.Header_container__dKQex .Header_lgSelected__\\+Rdum {
  border-bottom: 4px solid rgba(0, 0, 0, 0.239);
}

.Header_container__dKQex .Header_flags__R60cW {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_flagIcon__zr2DX {
  width: 1.5rem;
  margin-right: 10px;
}

.Header_container__dKQex .Header_flags__R60cW .Header_flagIcon__zr2DX:hover {
  transition: 0.3s;
  scale: 1.09;
}

.Header_container__dKQex .Header_agencyTitle__7TnGs {
  font-size: 1.75rem;
  text-transform: uppercase;
}

.Header_container__dKQex .Header_selectAgency__ARzPI {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media print {
  .Header_container__dKQex {
    display: none !important;
  }
  .Header_flagIcon__zr2DX {
    display: none !important;
  }
  .Header_flags__R60cW {
    display: none !important;
  }
  .Header_agencyTitle__7TnGs {
    display: none !important;
  }
  .Header_selectAgency__ARzPI {
    display: none !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n}\n\n.container .headerLogo {\n  margin-left: 30px;\n  width: 15rem;\n  max-height: 20rem;\n  object-fit: contain;\n}\n\n.container .lgSelected {\n  border-bottom: 4px solid rgba(0, 0, 0, 0.239);\n}\n\n.container .flags {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.flagIcon {\n  width: 1.5rem;\n  margin-right: 10px;\n}\n\n.container .flags .flagIcon:hover {\n  transition: 0.3s;\n  scale: 1.09;\n}\n\n.container .agencyTitle {\n  font-size: 1.75rem;\n  text-transform: uppercase;\n}\n\n.container .selectAgency {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n@media print {\n  .container {\n    display: none !important;\n  }\n  .flagIcon {\n    display: none !important;\n  }\n  .flags {\n    display: none !important;\n  }\n  .agencyTitle {\n    display: none !important;\n  }\n  .selectAgency {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Header_container__dKQex`,
	"headerLogo": `Header_headerLogo__bqkbi`,
	"lgSelected": `Header_lgSelected__+Rdum`,
	"flags": `Header_flags__R60cW`,
	"flagIcon": `Header_flagIcon__zr2DX`,
	"agencyTitle": `Header_agencyTitle__7TnGs`,
	"selectAgency": `Header_selectAgency__ARzPI`
};
export default ___CSS_LOADER_EXPORT___;
