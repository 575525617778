// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addConfigure_container__-eX3p {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.addConfigure_container__-eX3p .addConfigure_titleContainer__pH2zC {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addConfigure_container__-eX3p .addConfigure_titleContainer__pH2zC .addConfigure_title__GCzzt {
  font-size: 2rem;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Configure/addConfigure.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,+BAA+B;EAC/B,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 80px;\n}\n\n.container .titleContainer {\n  width: 60%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.container .titleContainer .title {\n  font-size: 2rem;\n  font-family: Roboto, sans-serif;\n  font-weight: 300;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `addConfigure_container__-eX3p`,
	"titleContainer": `addConfigure_titleContainer__pH2zC`,
	"title": `addConfigure_title__GCzzt`
};
export default ___CSS_LOADER_EXPORT___;
