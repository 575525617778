import apiClient from "../../utils/api.client";
import { getSaaId } from "../../utils/session.utils";

export const getTableRanking = async (
  organizationId,
  agencia,
  subAgencia,
  codigoJuego,
  fechaDesde,
  fechaHasta,
  maquinaConVenta,
) => {
  try {
    const saaId = getSaaId();
    const response = await apiClient.get(
      `/saaServices/saaServices/services/abaco/rankingPorBoca?organizacionId=${saaId}&agencia=${agencia}&subAgencia=${subAgencia}&codigoJuego=${codigoJuego}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&habilitadas=true&conVenta=${maquinaConVenta}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at getTableRanking EP: ", error);
    throw error;
  }
};

export const getProvRanking = async (
  organizationId,
  codigoJuego,
  fechaDesde,
  fechaHasta,
) => {
  try {
    const saaId = getSaaId();
    const response = await apiClient.get(
      `/saaServices/saaServices/services/abaco/rankingprovincias?organizacionId=${saaId}&codigoJuego=${codigoJuego}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at getProvRanking EP: ", error);
    throw error;
  }
};

export const getExpiredRanking = async (
  organizationId,
  agencia,
  codigoJuego,
  fechaDesde,
  fechaHasta,
) => {
  try {
    const saaId = getSaaId();
    const response = await apiClient.get(
      `/saaServices/saaServices/services/abaco/rankingcaducos?organizacionId=${saaId}&agencia=${agencia}&codigoJuego=${codigoJuego}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at getExpiredRanking EP: ", error);
    throw error;
  }
};
