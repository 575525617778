import React, { useEffect, useState } from "react";
import s from "./Detalles.module.css";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getPlayDetails } from "../../../../../api/saaservices/prize";

import {
  Modal,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Button,
} from "@mui/material";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

const ModalJugadas = ({ openPlay, setOpenPlay, handlePlay, data }) => {
  const { t } = useTranslation();
  const { textColor } = useSelector((state) => state.local);
  // const { organizationId } = useSelector((state) => state.gamma)

  const [loading, setLoading] = useState(false);
  const [play, setPlay] = useState(null);
  const [error, setError] = useState(false);

  return (
    <Modal open={openPlay} onClose={() => setOpenPlay(false)}>
      <Grid container>
        <Grid
          xs={12}
          md={10}
          lg={8}
          item
          container
          className={s.boxModal}
          sx={{ bgcolor: "#EEEEEE" }}
        >
          <Grid
            container
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={11} className={s.exit}>
              <div className={s.titleModal}>
                <BarChartRoundedIcon sx={{ mr: 1, color: "primary.main" }} />
                <p>Detalle</p>
              </div>
              <CloseIcon
                onClick={() => setOpenPlay(false)}
                className={s.exitIcon}
                sx={{ color: "primary.dark" }}
              />
            </Grid>

            {loading ? (
              <Grid
                item
                xs={11}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                }}
              >
                <CircularProgress sx={{ fontSize: "3rem" }} />
              </Grid>
            ) : data.length == 0 ? (
              <Grid
                item
                xs={11}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                }}
              >
                <ErrorIcon color="error" sx={{ fontSize: "3rem" }} />
                <p>Disculpe, no hay infomación para mostrar.</p>
                <Button variant="outlined" sx={{ mt: 2 }}>
                  {t("retry")}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ height: "400px" }}>
                <TableContainer className={s.tableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                          fontWeight: 500,
                          fontSize: ".8rem",
                        }}
                      >
                        Fecha y Hora
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                          fontWeight: 500,
                          fontSize: ".8rem",
                        }}
                      >
                        Importe
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                          fontWeight: 500,
                          fontSize: ".8rem",
                        }}
                      >
                        Operación
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                          fontWeight: 500,
                          fontSize: ".8rem",
                        }}
                      >
                        Plataforma
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                          fontWeight: 500,
                          fontSize: ".8rem",
                        }}
                      >
                        Número de Operación
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                          fontWeight: 500,
                          fontSize: ".8rem",
                        }}
                      >
                        Caja
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {data.map((detalleMP, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell>{detalleMP?.fecha_pago}</TableCell>
                            <TableCell>
                              {detalleMP?.type === "DEVOLUCION" ? (
                                <p style={{ color: "red" }}>
                                  {`$ ${
                                    detalleMP?.importe === 0
                                      ? "-"
                                      : detalleMP.importe
                                  }`}
                                </p>
                              ) : (
                                <p>
                                  {`$ ${
                                    detalleMP?.importe === 0
                                      ? "-"
                                      : detalleMP.importe
                                  }`}
                                </p>
                              )}
                            </TableCell>
                            <TableCell>
                              {detalleMP?.type[0] +
                                detalleMP?.type.substring(1).toLowerCase()}
                            </TableCell>
                            <TableCell>{detalleMP?.plataforma}</TableCell>
                            <TableCell>{detalleMP?.n_operacion}</TableCell>
                            <TableCell>
                              {`${detalleMP.caja.agencia}-${detalleMP.caja.subagencia}-${detalleMP.caja.n_maquina}`}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalJugadas;
