import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const exportExcel = (datas, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(datas);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const exportExcel2Sheets = (datas, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(datas[0]);
  const ws2 = XLSX.utils.json_to_sheet(datas[1]);
  const wb = {
    Sheets: { data: ws, data2: ws2 },
    SheetNames: ["data", "data2"],
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export function exportToExcel(array, filename) {
  const worksheet = XLSX.utils.json_to_sheet(array);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${filename}.xlsx`);
}

export function exportPDF(datas, fileName) {
  const pdfDoc = pdfMake.createPdf({
    content: [
      { text: fileName, style: "header" },
      {
        table: {
          headerRows: 1,
          body: datas,
        },
      },
    ],
    styles: {
      header: { fontSize: 16, bold: true, marginBottom: 10 },
    },
  });

  // Descargamos el archivo PDF generado
  pdfDoc.download(fileName + ".pdf");
}
