import { configureStore } from "@reduxjs/toolkit";

import gammaReducer from "../store/slices/gammaSlice";
import localReducer from "../store/slices/localSlice";
import pointsReducer from "./slices/pointsSlice";
import userReducer from "./slices/userSlice";
import paginationReducer from "./slices/paginationSlice";
import myAgencies from "./slices/my_agencies";
import errorsSlice from "./slices/errors";
import premiosReducer from "./slices/dataSlice";
import storage from "redux-persist/lib/storage";
import session from "./slices/session";

import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: "gamma",
  storage,
};

const reducers = combineReducers({
  gamma: gammaReducer,
  premios: premiosReducer,
  local: localReducer,
  point: pointsReducer,
  users: userReducer,
  pagination: paginationReducer,
  myAgencies: myAgencies,
  errors: errorsSlice,
  session: session,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
