import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import es from "../Languages/es.json";
import pt from "../Languages/pt.json";
import { useSelector } from "react-redux";
import { languages } from "../config/strings";

export const GetLanguage = () => {
  const { language } = useSelector((state) => state.local);

  i18n.use(initReactI18next).init({
    resources: {
      es: {
        translation: es,
      },
      pt: {
        translation: pt,
      },
    },
    lng: language,
    fallbackLng: languages.es,

    interpolation: {
      escapeValue: false,
    },
  });
};
