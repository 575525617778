import { useSelector } from "react-redux";

export const ColorPalette = () => {
  const { uiConfig } = useSelector((state) => state.local);
  const colorPalette = {
    palette: {
      primary: {
        main: uiConfig.primary.main,
        light: uiConfig.primary.light,
        dark: uiConfig.primary.dark,
      },
      secondary: {
        main: uiConfig.secondary.main,
        light: uiConfig.secondary.light,
        dark: uiConfig.secondary.dark,
      },
      tertiary: {
        main: "#e3f2fd",
        light: "#e3f2fd",
        dark: "#914795",
      },
      abaco: {
        main: "#FFFFFF",
        light: "#FFCC33",
        secondary: "#9999CC",
        dark: "#FFE495"
      },
      purple: {
        main: "#9999CC",
        secondary: "#9999CC",
        dark: "#9999CC",
        light: "#9999CC",
      }
    },
  };
  return colorPalette;
};
