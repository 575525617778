import styles from "./headerMobile.module.css";
import React from "react";
import Grid from "@mui/material/Grid";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import argentinaFlag from "../../../assets/argentina-flag.png";
import brazilFlag from "../../../assets/brazil-falg.png";
import { languages } from "../../../config/strings";
import { updateLanguage } from "../../../store/slices/localSlice";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { LogoLoader } from "../../Shared/SkeletonLoaders";

export const HeaderMobile = (props) => {
  const [logo, setLogo] = useState("");
  const { currentLogo } = useSelector((state) => state.local);
  const dispatch = useDispatch();

  useEffect(() => {
    setLogo(currentLogo?.imgBase64);
  }, [currentLogo]);

  const changeLanguage = (lng) => {
    dispatch(updateLanguage(lng));
  };

  return (
    <Grid
      container
      className={styles.container}
      sx={{ backgroundColor: "primary.main" }}
    >
      <Grid item xs={2} className={styles.burger}>
        <MenuRoundedIcon
          sx={{ fontSize: "3rem" }}
          color="secondary"
          onClick={props.openNav}
        />
      </Grid>
      <Grid item xs={8} className={styles.logo}>
        {logo?.length ? (
          <img className={styles.headerLogo} src={logo} alt="logo" />
        ) : (
          <LogoLoader />
        )}
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
};
