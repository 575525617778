import apiClient from "../../utils/api.client";
import { getOrganizationId } from "../../utils/session.utils";

export const getAgencieSettlements = async (
  agencie,
  dateFrom,
  dateTo,
  page,
) => {
  try {
    const response = await apiClient.get(
      `/gamma-movements/agencies/${agencie.id}/agencySettlements?pageNumber=${page || 1
      }&pageSize=20&orderCriteria=ASC&orderField=id&fromDate=${dateFrom}&toDate=${dateTo}&settlementPaymentStatus=CHARGE_PENDING`,
    );

    const data = response.data;
    return data;

    // const response = await fetch(
    //   `/gamma-movements/agencies/${agencie.id}/agencySettlements?pageNumber=${page || 1
    //   }&pageSize=20&orderCriteria=ASC&orderField=id&fromDate=${dateFrom}&toDate=${dateTo}&settlementPaymentStatus=CHARGE_PENDING`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       accept: "application/json",
    //     },
    //   }
    // );
    // return response.json();
  } catch (error) {
    console.log(error);
  }
};

// FIX: Add organization, the ep belongs to LOAD and EXTRACT services that were replaced with the new ETL
export const getHomologatedGames = async (
  organizationId = getOrganizationId(),
) => {
  try {
    // const response = await fetch(`/gamma-general/homologated/games`, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     accept: "application/json",
    //   },
    // });
    // const data = await response.json();
    // return data
    const response = await apiClient.get(
      `/gamma-general/homologated/games?organizationId=${organizationId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log('Error at getHomologatedGames EP', error);
    throw error
  }
};

export const getOrganizationConfiguration = async (organization) => {
  try {
    // const response = await fetch(
    //   `/gamma-movements/organizations/${organization}/configurations`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       accept: "application/json",
    //     },
    //   }
    // );
    // return response.json();
    const response = await apiClient.get(
      `/gamma-movements/organizations/${organization}/configurations`,
    );
    const data = response.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getSettlements = async (agencie, subagency) => {
  try {
    // const response = await fetch(
    //   `/gamma-movements/settlements/agencies/${agencie?.id
    //   }/report?pageSize=10000000&movementSize=10000000${subagency && agencie?.id !== subagency?.id
    //     ? `&agencyIds=${subagency?.id}`
    //     : ""
    //   }`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       accept: "application/json",
    //     },
    //   }
    // );
    // return response.json();
    const response = await apiClient.get(
      `/gamma-movements/settlements/agencies/${agencie?.id
      }/report?pageSize=10000000&movementSize=10000000${subagency && agencie?.id !== subagency?.id
        ? `&agencyIds=${subagency?.id}`
        : ""
      }`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};
