import { getDate, setMilliseconds } from "date-fns";

export function today() {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  //   let currentDate = `${year}-${month}-${day}`;
  let currentDate =
    (day < 10 ? "0" : "") +
    day +
    "/" +
    (month < 10 ? "0" : "") +
    month +
    "/" +
    year;
  return currentDate;
}

export function lastWeek() {
  let fechaActual = new Date();
  let diaDelMes = fechaActual.getDate();

  if (diaDelMes === 1) {
    fechaActual.setMonth(fechaActual.getMonth() - 7);
    fechaActual.setDate(0);
  } else {
    fechaActual.setDate(diaDelMes - 7);
  }
  let año = fechaActual.getFullYear();
  let mes = fechaActual.getMonth() + 1;
  let dia = fechaActual.getDate();
  let currentDate =
    (dia < 10 ? "0" : "") + dia + "/" + (mes < 10 ? "0" : "") + mes + "/" + año;
  return currentDate;
}

export function CalendarioHoy() {
  let fechaActual = new Date();
  let año = fechaActual.getFullYear();
  let mes = fechaActual.getMonth() + 1;
  let dia = fechaActual.getDate();
  let fecha =
    año + "-" + (mes < 10 ? "0" : "") + mes + "-" + (dia < 10 ? "0" : "") + dia;
  return fecha;
}

export function CalendarioSemanaPasada() {
  let fechaActual = new Date();
  fechaActual.setDate(fechaActual.getDate() - 7);
  let año = fechaActual.getFullYear();
  let mes = fechaActual.getMonth() + 1;
  let dia = fechaActual.getDate();
  let fecha =
    año + "-" + (mes < 10 ? "0" : "") + mes + "-" + (dia < 10 ? "0" : "") + dia;
  return fecha;
}

export function PrimerDiaDelMes() {
  let fechaActual = new Date();
  fechaActual.setDate(1);
  let año = fechaActual.getFullYear();
  let mes = fechaActual.getMonth() + 1;
  let dia = fechaActual.getDate();
  let fecha = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${año}`;
  console.log(`F.e.c.h.a: ${fecha}`);
  return fecha;
}

export function primerDiaDelMes() {
  const date = new Date();
  date.setDate(1);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  //   let currentDate = `${year}-${month}-${day}`;
  let currentDate =
    (day < 10 ? "0" : "") +
    day +
    "/" +
    (month < 10 ? "0" : "") +
    month +
    "/" +
    year;
  console.log("primer dia del mes: ", currentDate);
  return currentDate;
}

export function NoventaDias() {
  let fechaNoventaDias = new Date();
  fechaNoventaDias.setDate(-90);
  return fechaNoventaDias;
}

export function primeroDeOctubre() {
  let fecha = new Date();
  fecha.setFullYear(2023, 9, 1);
  return fecha;
}
