import {
  setConcentratorSelectedError,
  setAgencieSelectedError,
  setMy_AgenciesError,
} from "../../store/slices/errors";
import { getOrganizationId } from "../../utils/session.utils";
import apiClient from "../../utils/api.client";
const api_conf_base_url = process.env.REACT_APP_API_CONF_URL;

// NOTE: If there is an id there is no reason for organizationId
export const getAgencieById = async (payload, dispatch) => {
  try {
    const response = await apiClient.get(`/gamma-agencies/agencies/${payload}`);
    const data = response.data;

    return data;
  } catch (error) {
    console.log(error);
    dispatch(
      setMy_AgenciesError({ error: true, code: error.status || error.message }),
    );
    dispatch(
      setAgencieSelectedError({
        error: true,
        code: error.status || error.message,
      }),
    );
    dispatch(
      setConcentratorSelectedError({
        error: true,
        code: error.status || error.message,
      }),
    );
  }
};

export const getAgencieByIdAwait = async (payload) => {
  try {
    // const response = await fetch(`/gamma-agencies/agencies/${payload}`, {
    //     method: "GET",
    //     headers: { "Content-Type": "application/json", "accept": "application/json" }
    // })

    const response = await apiClient.get(`/gamma-agencies/agencies/${payload}`);

    // const data = await response.json()

    const data = response.data;

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/*Require OrganizationID*/
export const editAgencieData = async (payload) => {
  try {
    const response = await apiClient.put(`/gamma-agencies/agencies`, payload);
    const data = response.data

    return data
  } catch (error) {
    console.error("Error at editAgencieData EP: ", error);

    throw error;
  }
};

export const addRelationship = async (agencieId, payload) => {
  try {
    // return await fetch(`/gamma-agencies/agencies/${agencieId}/relationships`, {
    //     method: "POST",
    //     body: JSON.stringify(payload),
    //     headers: { "Content-Type": "application/json", "accept": "application/json" }
    // })
    const response = await apiClient.post(
      `/gamma-agencies/agencies/${agencieId}/relationships`,
      payload,
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAgencieConcentrator = async (organizationId) => {
  try {
    // const response = await fetch(`/gamma-agencies/organizations/${organizationId}/agencies?pageNumber=1&pageSize=20&orderCriteria=ASC&orderField=name&agencyKind=CONCENTRATOR`, {
    //     method: "GET",
    //     headers: { "Content-Type": "application/json", "accept": "application/json" }
    // })
    // return await response.json()
    const response = await apiClient.get(
      `/gamma-agencies/organizations/${organizationId}/agencies?pageNumber=1&pageSize=20&orderCriteria=ASC&orderField=name&agencyKind=CONCENTRATOR`,
    );

    const data = response.data;

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getRelationsShips = async (agencieId) => {
  //     try{
  //         const response = await fetch(`/gamma-agencies/agencies/${agencieId}/relationships?pageNumber=1&pageSize=20&orderCriteria=ASC&orderField=name`,{
  //             method: "GET",
  //             headers: {"Content-Type": "application/json", "accept": "application/json"}
  //         })
  //         return response.json();
  //     }catch(e){
  //         console.log(e)
  //     }
  // }

  try {
    const response = await apiClient.get(
      `/gamma-agencies/agencies/${agencieId}/relationships?pageNumber=1&pageSize=20&orderCriteria=ASC&orderField=name`,
    );

    const data = response.data;

    return data;
  } catch (error) {
    console.log("Error from getRelationShips");
  }
};
export const deleteRelationShip = async (agencieId, relationshipId) => {
  try {
    const response = await apiClient.delete(
      `/gamma-agencies/agencies/${agencieId}/relationships/${relationshipId}`,
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const agencieSearch = async (
  organizationId,
  payload,
  agencyKind,
  page,
  status,
  sort,
) => {
  try {
    // const response = await fetch(`/gamma-agencies/organizations/${organizationId}/agencies?${page ? `pageNumber=${page}&` : ''}pageSize=10&orderCriteria=${sort?.criteria ? sort?.criteria : 'ASC'}${status ? `&status=${status}` : ''}&orderField=${sort?.field ? sort?.field : 'name'}&agencyKind=${agencyKind || "CONCENTRATOR"}${payload ? `&query=${payload}` : ''}`, {
    //     method: "GET",
    //     headers: { "Content-Type": "application/json", "accept": "application/json" }
    // })
    // return response.json()

    const response = await apiClient.get(
      `/gamma-agencies/organizations/${organizationId}/agencies?${page ? `pageNumber=${page}&` : ""}pageSize=10&orderCriteria=${sort?.criteria ? sort?.criteria : "ASC"}${status ? `&status=${status}` : ""}&orderField=${sort?.field ? sort?.field : "name"}&agencyKind=${agencyKind || "CONCENTRATOR"}${payload ? `&query=${payload}` : ""}`,
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const agencieSearchByNumber = async (organizationId, query) => {
  // return fetch(`/gamma-agencies/organizations/${organizationId}/agencies/findByNumberAndSubNumber?number=${query.number}${query.subnumber ? `&subNumber=${query.subnumber}` : ''}`, {
  //     method: "GET",
  //     headers: { "Content-Type": "application/json", "accept": "application/json" }
  // })
  //     .then(res => {
  //         if (res.status === 200) {
  //             return res.json();
  //         } else {
  //             throw res
  //         }
  //     })
  //     .catch(e => { throw e })
  try {
    const response = await apiClient.get(
      `/gamma-agencies/organizations/${organizationId}/agencies/findByNumberAndSubNumber?number=${query.number}${query.subnumber !== null && query.subnumber !== undefined ? `&subNumber=${query.subnumber}` : ""}`,
    );

    const data = response.data;

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getFavsAgencies = async (userId, orgId) => {
  try {
    const response = await apiClient.get(
      `/api-configuration/settings/bookmarks/${userId}?organizationId=${orgId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const sendFavsAgencies = async (userId, orgId, payload) => {
  const jsonToSend = {
    userUUID: userId,
    content: payload.map((agencie) => agencie?.id),
    organizationId: orgId,
  };
  try {
    const response = await apiClient.post(
      `/api-configuration/settings/bookmarks`,
      jsonToSend,
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendRelationedAgencies = async (userId, orgId, payload) => {
  const intPayload = payload.map((str) => parseInt(str));
  console.log("add Pto Vta. payload", intPayload);
  const jsonToSend = {
    userUUID: userId,
    content: intPayload,
    organizationId: orgId,
  };
  try {
    const response = await apiClient.post(
      `/api-configuration/settings/users/agencies`,
      jsonToSend,
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAgenciesRelationed = async (userUuid, orgId) => {
  try {
    const response = await apiClient.get(
      `/api-configuration/settings/users/agencies/${userUuid}?organizationId=${orgId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

/*Requiere OrganizationID*/
export const getAllUsersFromAgencies = async (
  payload,
  organizationId = getOrganizationId(),
) => {
  try {
    const response = await apiClient.post(
      `/api-configuration/settings/users/agencies/findByAgency?pageNumber=1&pageSize=999&orderCriteria=ASC&userUUID=userUUID&organizationId=${organizationId}`,
      payload,
    );
    return response.data.content;
  } catch (error) {
    console.log(error);
  }
};

export const getSubAgenciesByAgencie = async (
  organizationId,
  agencyId,
  page,
  subnumber,
) => {
  try {
    const response = await apiClient.get(
      `/gamma-agencies/organizations/${organizationId}/agencies/findByNumberAndSubNumber?number=${agencyId}&subNumber=${subnumber}`,
    );
    // console.log("respuesta del llamado sub AGENCIAS", response)
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};
