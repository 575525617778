// import react
import React from "react";
// import pages
import { Login } from "../../../../components/Pages/Login";
import { NavBar } from "../../../../components/Navigation/NavBar";
import { Main } from "../../../../components/Pages/Main";
import { Agencias } from "../../../../components/Pages/Agencias";
import { CuentaCorriente } from "../../../../components/Pages/CuentaCorriente";
import { Premios } from "../../../../components/Pages/Premios";
import { ControlCaja } from "../../../../components/Pages/ControlCaja";
import { OnLine } from "../../../../components/Pages/OnLine";
import { Ranking } from "../../../../components/Pages/Ranking";
import { Documentos } from "../../../../components/Pages/Documentos";
import { Ayuda } from "../../../../components/Pages/Ayuda";
import { NavBarAdmin } from "../../../../components/Navigation/NavBarAdmin";
import { TermsAndConditions } from "../../../../components/Pages/TermsAndConditions";
import { Colors } from "../../../Pages/Colors";
import Concentrators from "../../../../components/Pages/Concentrators/Concentrators";
import { LogOut } from "../../../../actions/LogOut";
import { LoginTerms } from "../../../../components/Pages/LoginTerms";
import { Configure } from "../../../../components/Pages/Configure";
import PrivateRoute from "../../../../helpers/PrivateRoute";
import AgencieSelected from "../../../Pages/Concentrators/AgencieSelected/AgencieSelected.jsx";
import { ChangeTerms } from "../../../../components/Pages/TermsAndConditions/changeTerms";
import { AddTerms } from "../../../../components/Pages/TermsAndConditions/addTerms.jsx/index";
import { CreateConfigure } from "../../../../components/Pages/Configure/addConfigure";
import { AboutVersion } from "../../../../components/Pages/AboutVersion";
import { Preload } from "../../../../helpers/Preload";
import { Organizations } from "../../../Pages/Organizations";
import { Logos } from "../../../Pages/Logos";
import ConcentratorSelected from "../../../Pages/Concentrators/ConcentratorSelected/ConcentratorSelected";
import { ServerError } from "../../../ErrorPages/ServerError";
import UsersRefactor from "../../../Pages/Refactor User";
import { MediosDigitales } from "../../../Pages/MediosDigitales/index.jsx";
import ManualDeUso from "../../../Pages/ManualDeUso";
import { Navigate } from "react-router-dom";
import store from "../../../../store/index.js";
import { ResetPassword } from "../../../Pages/ResetPassword/index.jsx";

export const routes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/logout",
    element: <LogOut />,
  },
  {
    path: "/main",
    element: (
      <PrivateRoute>
        <NavBar />
      </PrivateRoute>
    ),
    errorElement: <Login />,
    children: [
      { path: "panel", element: <Main /> },
      { path: "agencias", element: <Agencias /> },
      { path: "cuenta-corriente/:num", element: <CuentaCorriente /> },
      { path: "cuenta-corriente", element: <CuentaCorriente /> },
      { path: "premios", element: <Premios /> },
      { path: "premios/:num", element: <Premios /> },
      { path: "control-caja", element: <ControlCaja /> },
      { path: "control-caja/:num", element: <ControlCaja /> },
      { path: "on-line", element: <OnLine /> },
      { path: "medios-digitales", element: <MediosDigitales /> },
      { path: "manual-de-uso", element: <ManualDeUso /> },
      { path: "ranking", element: <Ranking /> },
      { path: "documentos", element: <Documentos /> },
      { path: "ayuda", element: <Ayuda /> },
      { path: "about", element: <AboutVersion /> },
    ],
  },
  {
    path: "/check-user",
    element: (
      <PrivateRoute>
        <Preload />
      </PrivateRoute>
    ),
  },
  {
    path: "/terms",
    element: (
      <PrivateRoute>
        <LoginTerms />
      </PrivateRoute>
    ),
  },
  {
    path: "/organizations",
    element: (
      <PrivateRoute>
        <Organizations />
      </PrivateRoute>
    ),
  },
  {
    path: "/server-error/:code",
    element: (
      <PrivateRoute>
        <ServerError />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin",
    element: (
      <PrivateRoute>
        <NavBarAdmin />
      </PrivateRoute>
    ),
    children: [
      { path: "users", element: <UsersRefactor /> },
      { path: "sales_points", element: <Concentrators /> },
      {
        path: "sales_points/:name/:numAndSub/:id",
        element: <ConcentratorSelected />,
      },
      { path: "sale_point/:name/:numAndSub/:id", element: <AgencieSelected /> },
      { path: "terms_and_conditions", element: <TermsAndConditions /> },
      { path: "terms_and_conditions/add", element: <AddTerms /> },
      { path: "terms_and_conditions/:id", element: <ChangeTerms /> },
      { path: "colors", element: <Colors /> },
      { path: "logos", element: <Logos /> },
      { path: "configure", element: <Configure /> },
      { path: "configure/create", element: <CreateConfigure /> },
    ],
  },
];
