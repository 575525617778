import { useDispatch } from "react-redux";
import { updateTextColor } from "../store/slices/localSlice";

export const ConvertColorText = (primaryColor) => {
  const dispatch = useDispatch();
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (primaryColor.length === 4) {
    r = "0x" + primaryColor[1] + primaryColor[1];
    g = "0x" + primaryColor[2] + primaryColor[2];
    b = "0x" + primaryColor[3] + primaryColor[3];

    // 6 digits
  } else {
    r = "0x" + primaryColor[1] + primaryColor[2];
    g = "0x" + primaryColor[3] + primaryColor[4];
    b = "0x" + primaryColor[5] + primaryColor[6];
  }

  const rgb = [r, g, b];

  const brightness = Math.round(
    (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) /
      1000,
  );
  const textColor = brightness > 125 ? "black" : "white";

  dispatch(updateTextColor(textColor));
};
