import zoneData from "../../mock-data/zoneData";
import uiConfig from "../../mock-data/uiConfig";
import favAgencies from "../../mock-data/favAgencies";
import { MAIN_URL } from "../../config/strings";
import allTerms from "../../mock-data/terms/allTerms.json";
import orgTerms from "../../mock-data/terms/orgTerms.json";
import apiClient from "../../utils/api.client";

/*Consultar si requiere organization ID o que con TEJE*/
export const getZoneData = async (userId, location) => {
  // return fetch(`${MAIN_URL.localPath}/zonedata/${userId}?location=${location}`)
  //     .then((response) => response.json())
  //     .then((json) => { return json })
  //     .catch((err) => console.log(err));

  try {
    const response = await apiClient.get(
      `${MAIN_URL.localPath}/zonedata/${userId}?location=${location}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getUiConfig = async (userId) => {
  // return fetch(`${MAIN_URL.localPath}/uiconfig/${userId}`)
  //     .then((response) => response.json())
  //     .then((json) => { return json })
  //     .catch((err) => console.log(err));

  try {
    const response = await apiClient.get(
      `${MAIN_URL.localPath}/uiconfig/${userId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getLegals = async (organizationId) => {
  // return fetch(`/api-terms/terms/getCurrentTerms/${organizationId}`)
  //     .then((response) => response.json())
  //     .then((json) => { return json })
  //     .catch((err) => console.log(err));

  try {
    const response = await apiClient.get(
      `/api-terms/terms/getCurrentTerms/${organizationId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getLegal = async (userId, orgId, termsId) => {
  // return fetch(`/api-terms/terms/agreement?userUUID=${userId}&termsConditionID=${termsId}&organizationId=${orgId}`)
  //     .then((response) => response.json())
  //     .then((json) => { return json })
  //     .catch((err) => console.log(err));
  try {
    const response = await apiClient.get(
      `/api-terms/terms/agreement?userUUID=${userId}&termsConditionID=${termsId}&organizationId=${orgId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateLegal = async (payload) => {
  // return fetch(`/api-terms/terms/agreement`, {
  //     method: "POST",
  //     body: JSON.stringify(payload),
  //     headers: { "Content-Type": "application/json", "accept": "application/json" }
  // })
  //     .then((json) => { return json })
  //     .catch((err) => console.log(err));
  try {
    const response = await apiClient.post(
      `/api-terms/terms/agreement`,
      payload,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error at updateLegal", error);
    throw error;
  }
};

/*Consultar si requiere organization ID o que con TEJE*/
export const getFavAgencies = async (userId) => {
  // return fetch(`${MAIN_URL.localPath}/favAgencies/${userId}`)
  //     .then((response) => response.json())
  //     .then((json) => { return json })
  //     .catch((err) => console.log(err));
  try {
    const response = await apiClient.get(
      `${MAIN_URL.localPath}/favAgencies/${userId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};
