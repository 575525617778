export const searchInArray = (array, imput) => {
  return array.filter(
    (persona) =>
      persona.firstname.toLowerCase().includes(imput.toLowerCase()) ||
      persona.lastname.toLowerCase().includes(imput.toLowerCase()),
  );
};

export const searchpointsInArray = (array, imput, imputNumber) => {
  return array.filter(
    (point) =>
      point.name.toLowerCase().includes(imput.toLowerCase()) &&
      (point.number + "-" + point.subNumber).toString().includes(imputNumber),
  );
};
