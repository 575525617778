import React from "react";
import Chip from "@mui/material/Chip";
import styles from "./Chips.module.css";

const Chips = ({
  subAgencie,
  setOpenAcordion,
  selectedSubAgencie,
  setSelectedSubAgencie,
}) => {
  const handleSelectSubAgencie = (subAgencie) => {
    if (!(selectedSubAgencie?.id === subAgencie?.id)) {
      setSelectedSubAgencie(subAgencie);
    }
  };

  return (
    <div className={subAgencie ? styles.chipContainer : styles.noChips}>
      {subAgencie ? (
        <Chip
          size="small"
          label={`${subAgencie?.number}  ${
            subAgencie?.subNumber !== null ? "-" : ""
          } ${subAgencie?.subNumber !== null ? subAgencie.subNumber : ""}`}
          color={
            selectedSubAgencie?.id === subAgencie?.id ? "secondary" : "primary"
          }
          onClick={() => {
            handleSelectSubAgencie(subAgencie);
            setOpenAcordion(true);
          }}
          sx={{ width: "100%" }}
        />
      ) : (
        <Chip label="Sin SubAgencias" color="primary" />
      )}
    </div>
  );
};

export default Chips;
