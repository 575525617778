// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Organizations_container__vseap {
  width: 100%;
  height: 86vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

.Organizations_title__TYjRb {
  font-family: sans-serif;
  font-weight: 500;
  font-size: 3em;
}

.Organizations_container_terminos__07S2O {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Organizations_selectOrganizationImage__MiBTO {
  width: 40vw;
  margin-top: 5vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Organizations/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,eAAe;AACjB","sourcesContent":[".container {\n  width: 100%;\n  height: 86vh;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.title {\n  font-family: sans-serif;\n  font-weight: 500;\n  font-size: 3em;\n}\n\n.container_terminos {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.selectOrganizationImage {\n  width: 40vw;\n  margin-top: 5vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Organizations_container__vseap`,
	"title": `Organizations_title__TYjRb`,
	"container_terminos": `Organizations_container_terminos__07S2O`,
	"selectOrganizationImage": `Organizations_selectOrganizationImage__MiBTO`
};
export default ___CSS_LOADER_EXPORT___;
