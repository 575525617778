import { React, useState, useEffect } from "react";
import s from "./Caducos.module.css";
//helper
import { today } from "../../../../../helpers/today";
import { convertDate } from "../../../../../helpers/convertDate";
//Material
import {
  Grid,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
//icons
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import Loader from "../../../../Shared/Loader";
import Swal from "sweetalert2";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
//translation
import { useTranslation } from "react-i18next";

//datePickers
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//cellCreator
import { createPrizeRow } from "../../../../../helpers/tableFunctions";

//modals
import ModalApuestas from "../Modales/ModalApuestas";
import ModalJugadas from "../Modales/ModalDetalles";

//redux
import { useSelector } from "react-redux";
import { exportExcel, exportPDF } from "../../../../../helpers/exportExcel.js";
import { formatArg, formatJava } from "../../../../../config/strings";
import { configDate } from "../../../../../helpers/convertDate";
import {
  Currency,
  currencyFormat,
} from "../../../../../helpers/currencyConvert";
import { getHomologatedGames } from "../../../../../api/general/generalService";
import { getGameOffers } from "../../../../../api/games/GamesService.js";
import { getOrganizationId } from "../../../../../utils/session.utils.js";
import { getGamesSaa } from "../../../../../api/games/GamesService.js";
const Caducos = ({ data, filtro, loading, setPrint, print, logoImpresion, }) => {
  //Date Pickers state
  const [homologated_Games, setHomologated_Games] = useState([]);
  const [dateFrom, setDateFrom] = useState(filtro.from);
  const [dateTo, setDateTo] = useState(filtro.to);
  const { currentLogo } = useSelector((state) => state.local);
  const agencies = useSelector((state) => state.myAgencies.user_agencies);
  //modals states
  const [openDetails, setOpenDetails] = useState(false);
  const [openPlay, setOpenPlay] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const { textColor } = useSelector((state) => state.local);
  const { formatLocalDate, formatLocalDateTime } = configDate();
  //hooks
  const { t } = useTranslation();

  const { formatCurrency } = currencyFormat();

  const [expandItem, setExpandItem] = useState([]);
  const [openItems, setOpenItems] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    getGame();
  }, []);
  const toggleExpandAll = () => {
    setExpandAll((prev) => {
      if (openItems) {
        setExpandItem([]);
        return false;
      } else {
        setOpenItems(true);
        return true;
      }
    });
  };

  const toggleExpandItem = (index) => {
    if (expandItem.includes(index)) {
      setExpandItem((prev) => {
        let data = prev.filter((item) => item !== index);
        return data;
      });
    } else {
      return setExpandItem((prev) => [...prev, index]);
    }
  };

  useEffect(() => {
    if (expandItem.length) {
      setOpenItems(true);
    } else {
      setOpenItems(false);
    }
  }, [expandItem]);

  const handleDetails = () => {
    setOpenDetails(!openDetails);
  };

  const handlePlay = () => {
    setOpenPlay(!openPlay);
  };

  const exportItem = () => {
    const excelData = [];
    const parsing = data?.groups?.forEach((game) => {
      game.forEach((pdv) => {
        pdv.forEach((item) => {
          const itemExcel = {};
          itemExcel[t("salePoint")] = item.puntoDeVenta;
          itemExcel[t("game")] = item.juegoDescripcion;
          itemExcel[t("machine")] = item.codigoMaquina;
          itemExcel[t("coupon")] = item.numeroCupon;
          itemExcel[t("raffles")] = item.numeroSorteo || "-";
          itemExcel[t("caducity")] = item.fechaSorteo;
          itemExcel[t("prizes")] = item?.importePremio;
          excelData.push(itemExcel);
        });
      });
    });
    return excelData;
  };

  const exportToExcel = () => {
    Swal.fire({
      title: t("excelConfirm"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        exportExcel(exportItem(), `${t("prizes")} ${t("expired")}`);
      }
    });
  };

  const exportToPDF = () => {
    Swal.fire({
      title: t("excelConfirm"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        exportPDF(exportItem(), `${t("prizes")} ${t("expired")}`);
      }
    });
  };

  const handlePrint = () => {
    setPrint(true);
  };

  function getGame() {
    getHomologatedGames()
      .then((r) => {
        return (
          r.filter(
            (juego) =>
              juego.integration[0].organization.id ===
              agencies[0].organization.id &&
              juego.integration[0].module === "SAA",
          )
        )
      }).then((data) => {
        setHomologated_Games(data);
      })
      .catch((error) => {
        console.error('Error at getHomologatedGames', error)
      })

    getGamesSaa()
      .then((res) => {
        res.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setHomologated_Games(res);
      })
      .catch((error) => {
        console.log("Error at getGamesSaa", error)
      });
  }

  const filterGameName = () => {
    if (filtro.game === -1) {
      return "Todos";
    }
    const arr = homologated_Games.find((h) => h?.juego === filtro?.game);
    console.log("arr", arr);
    return arr.name;
  };
  return (
    <Grid className={s.container} container>
      <Grid className={s.filtersContainer} xs={12} container item>
        <Grid
          container
          item
          xs={12}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "primary.light",
            height: "7rem",
            borderRadius: 2,
            boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
          }}
        >
          <Grid
            item
            xs={7}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <ListItem
              sx={{
                backgroundColor: "#EEEEEE",
                borderRadius: 3,
                width: "30%",
                boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                padding: 0.5,
                paddingLeft: 1.5,
              }}
            >
              <ListItemText
                primary={t("coupons")}
                secondary={data?.size || "-"}
                primaryTypographyProps={{ fontSize: "1.1rem" }}
                secondaryTypographyProps={{ fontSize: "1.2rem" }}
              />
            </ListItem>
            <ListItem
              sx={{
                backgroundColor: "#EEEEEE",
                borderRadius: 3,
                width: "60%",
                boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                padding: 0.5,
                paddingLeft: 1.5,
              }}
            >
              <ListItemText
                primary={t("total")}
                secondary={data?.amount?.toLocaleString("es-AR", {
                  style: "currency",
                  currency: "ARS",
                  currencyDisplay: "symbol",
                })}
                primaryTypographyProps={{ fontSize: "1.1rem" }}
                secondaryTypographyProps={{ fontSize: "1.2rem" }}
              />
            </ListItem>
          </Grid>
          <Grid
            item
            container
            xs={5}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={() => handlePrint()}
              sx={{ height: "3rem" }}
            >
              <PrintOutlinedIcon
                sx={{ color: textColor, fontSize: "1.5rem" }}
              />
            </Button>
            <Button
              variant="contained"
              onClick={exportToExcel}
              sx={{ height: "3rem" }}
            >
              <NewspaperOutlinedIcon
                sx={{ color: textColor, fontSize: "1.5rem" }}
              />
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={11.5}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            mt: 2,
          }}
        >
          {/* <Button
            variant="contained"
            disabled={!data?.groups}
            onClick={() => toggleExpandAll()}
            sx={{ height: "3rem", mr: 1 }}
          >
            {openItems ? (
              <UnfoldLessIcon sx={{ color: textColor, fontSize: "1.5rem" }} />
            ) : (
              <UnfoldMoreIcon sx={{ color: textColor, fontSize: "1.5rem" }} />
            )}
            {openItems ? t("collapseAll") : t("expandAll")}
          </Button> */}
        </Grid>
        {filtro?.from && filtro?.to ? (
          <Grid
            item
            xs={11.3}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              mt: 4,
            }}
          >
            <p
              style={{ margin: "0 5px 0 0", fontSize: "1rem", fontWeight: 600 }}
            >
              Fecha:
            </p>
            <p
              style={{ margin: "0 2px 0 0", fontSize: "1rem", fontWeight: 300 }}
            >
              {filtro?.from}
            </p>
            <p
              style={{ margin: "0 2px 0 0", fontSize: "1rem", fontWeight: 300 }}
            >
              al {filtro?.to}
            </p>
          </Grid>
        ) : null}
      </Grid>

      {/*Tabla de juegos*/}
      <Grid className={s.gamesTable} xs={12} item>
        {loading ? (
          <Grid
            item
            xs={12}
            sx={{
              minHeight: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size="3rem" />
          </Grid>
        ) : !data?.groups ? (
          <Grid
            item
            xs={12}
            sx={{
              minHeight: "150px",
              fontSize: "1.8rem",
              fontWeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {t("noResults")}
          </Grid>
        ) : (
          <Grid item container xs={12}>
            {print !== true ? (
              data?.groups?.map((game, i) => (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Accordion
                    expanded={expandItem.includes(`t${i}`)}
                    key={`t${i}`}
                    className={s.accordion}
                    sx={{
                      width: "95%",
                      backgroundColor:
                        i % 2 === 0 ? "primary.main" : "primary.light",
                      "&:hover": { backgroundColor: "primary.dark" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={s.summary}
                      onClick={() => toggleExpandItem(`t${i}`)}
                    >
                      <Typography
                        sx={{ color: textColor }}
                        className={s.gameName}
                      >
                        <p className={s.p1}>{game[0][0].juegoDescripcion}</p>
                        <p className={s.p1}>
                          {" "}
                          {`${t("coupons")}:`}{" "}
                          {game?.reduce((acc, pdv) => acc + pdv?.length, 0)}
                        </p>
                        <p
                          style={{
                            justifyContent: "center",
                            backgroundColor:
                              textColor === "black" ? "white" : "",
                            width: "25%",
                            minWidth: "12rem",
                            padding: "0.5rem",
                            borderRadius: "10px",
                          }}
                        >
                          <span>{t("total")}:</span>
                          <span
                            style={{
                              fontWeight: textColor === "black" ? "bold" : 400,
                            }}
                          >
                            <Currency
                              value={game?.reduce(
                                (acc, pdv) =>
                                  acc +
                                  pdv?.reduce(
                                    (ac, item) => ac + item?.importePremio,
                                    0,
                                  ),
                                0,
                              )}
                            />
                          </span>
                        </p>
                      </Typography>
                    </AccordionSummary>
                    {expandItem.includes(`t${i}`) && (
                      <AccordionDetails className={s.accordionDetails}>
                        {game
                          .sort((a, b) => {
                            let subagencyA = a[0].puntoDeVenta.split("-")[1];
                            let subagencyB = b[0].puntoDeVenta.split("-")[1];
                            if (parseInt(subagencyA) < parseInt(subagencyB)) {
                              return -1;
                            }
                            if (parseInt(subagencyA) > parseInt(subagencyB)) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((ite, idx) => (
                            <Accordion
                              expanded={expandItem.includes(`tx${idx}`)}
                              key={`tx${idx}`}
                              className={s.accordion}
                              sx={{
                                backgroundColor:
                                  i % 2 === 0
                                    ? "primary.main"
                                    : "primary.light",
                                "&:hover": { backgroundColor: "primary.dark" },
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={s.summary}
                                onClick={() => toggleExpandItem(`tx${idx}`)}
                              >
                                <Typography
                                  sx={{ color: textColor }}
                                  className={s.gameName}
                                >
                                  <p className={s.p1}>{ite[0].puntoDeVenta}</p>
                                  <p className={s.p1}>
                                    {" "}
                                    {`${t("coupons")}:`} {ite?.length}
                                  </p>
                                  <p
                                    style={{
                                      justifyContent: "center",
                                      backgroundColor:
                                        textColor === "black" ? "white" : "",
                                      width: "25%",
                                      minWidth: "11rem",
                                      padding: "0.5rem",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <span>{t("total")}:</span>
                                    <span
                                      style={{
                                        fontWeight:
                                          textColor === "black" ? "bold" : 400,
                                      }}
                                    >
                                      <Currency
                                        value={ite?.reduce(
                                          (ac, item) =>
                                            ac + item?.importePremio,
                                          0,
                                        )}
                                      />
                                    </span>
                                  </p>
                                </Typography>
                              </AccordionSummary>
                              {expandItem.includes(`tx${idx}`) && (
                                <AccordionDetails
                                  className={s.accordionDetails}
                                >
                                  <TableContainer className={s.tableContainer}>
                                    <Table stickyHeader>
                                      <TableHead
                                        sx={{ bgcolor: "secondary.light" }}
                                      >
                                        <TableRow
                                          color="primary.main"
                                          className={s.tableRow}
                                        >
                                          <TableCell
                                            align="right"
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: 500,
                                            }}
                                            className={s.tableCell}
                                          >
                                            {t("machine")}
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: 500,
                                            }}
                                            className={s.tableCell}
                                          >
                                            {t("coupon")}
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: 500,
                                            }}
                                            className={s.tableCell}
                                          >
                                            {t("raffles")}
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: 500,
                                            }}
                                            className={s.tableCell}
                                          >
                                            {t("status")}
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: 500,
                                            }}
                                            className={s.tableCell}
                                          >
                                            {t("caducity")}
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: 500,
                                            }}
                                            className={s.tableCell}
                                          >
                                            {t("prizes")} ($)
                                          </TableCell>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {ite.map((item, i) => (
                                          <TableRow key={i}>
                                            <TableCell
                                              align="right"
                                              sx={{
                                                backgroundColor: data.color,
                                              }}
                                            >
                                              {item.codigoMaquina || "-"}
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              sx={{
                                                backgroundColor: data.color,
                                              }}
                                            >
                                              {item.numeroCupon || "-"}
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              sx={{
                                                backgroundColor: data.color,
                                              }}
                                            >
                                              {item.numeroSorteo || "-"}
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              sx={{
                                                backgroundColor: data.color,
                                              }}
                                            >
                                              {item.estado || "Caduco"}
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              sx={{
                                                backgroundColor: data.color,
                                              }}
                                            >
                                              {convertDate(item.fechaCaducidad)}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                backgroundColor: data.color,
                                              }}
                                              align="right"
                                            >
                                              {formatCurrency(
                                                item.importePremio,
                                              )}
                                            </TableCell>
                                            <TableCell align="right">
                                              <Tooltip
                                                title={t("moreInformation")}
                                                placement="left"
                                              >
                                                <InfoRoundedIcon
                                                  sx={{
                                                    color: "rgb(91, 150, 233)",
                                                  }}
                                                  className={s.icon}
                                                  onClick={() => {
                                                    handleDetails();
                                                    setDataModal(item);
                                                  }}
                                                />
                                              </Tooltip>
                                              <Tooltip
                                                title={t("playDetails")}
                                                placement="right"
                                              >
                                                <BarChartRoundedIcon
                                                  sx={{
                                                    color: "rgb(91, 150, 233)",
                                                  }}
                                                  className={s.icon}
                                                  onClick={() => {
                                                    handlePlay();
                                                    setDataModal(item);
                                                  }}
                                                />
                                              </Tooltip>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </AccordionDetails>
                              )}
                            </Accordion>
                          ))}
                      </AccordionDetails>
                    )}
                  </Accordion>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} id="caduco-tabla">
                {logoImpresion?.imgBase64.length ? (
                    <img className={s.headerLogo} style={{height: "3rem"}} src={logoImpresion?.imgBase64} alt="logo" />
                ) : (
                  <></>
                )}
                <Grid>
                  <h4 className="texto">{t("expired")}</h4>
                  <h4 className="texto">
                    {t("settlementDay")}: {filtro?.from} al {filtro?.to}
                  </h4>
                  <h4 className="texto">
                    {t("settlementDay")}: {filtro?.from} al {filtro?.to}
                  </h4>
                  <h4 className="texto">
                    {t("game")}: {filterGameName()}
                  </h4>
                </Grid>

                {data?.groups?.map((game, i) =>
                  game.map((ite) => (
                    <TableContainer className={s.tableContainer}>
                      <Table className={`print-table`}>
                        <TableHead sx={{ bgcolor: "secondary.light" }}>
                          <TableRow color="primary.main" className={s.tableRow}>
                            <TableCell
                              sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                              className={s.tableCell}
                            >
                              {t("salePoint")}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                              className={s.tableCell}
                            >
                              {t("game")}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                              className={s.tableCell}
                            >
                              {t("machine")}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                              className={s.tableCell}
                            >
                              {t("coupon")}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                              className={s.tableCell}
                            >
                              {t("raffles")}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                              className={s.tableCell}
                            >
                              {t("status")}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                              className={s.tableCell}
                            >
                              {t("caducity")}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                              align="right"
                              className={s.tableCell}
                            >
                              {t("prizes")} ($)
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ite.map((item, i) => (
                            <TableRow key={i}>
                              <TableCell
                                className="alinear-derecha"
                                sx={{ backgroundColor: data.color }}
                              >
                                {item?.puntoDeVenta || "-"}
                              </TableCell>
                              <TableCell sx={{ backgroundColor: data.color }}>
                                {item.juegoDescripcion || "-"}
                              </TableCell>
                              <TableCell
                                className="alinear-derecha"
                                sx={{ backgroundColor: data.color }}
                              >
                                {item?.codigoMaquina || "-"}
                              </TableCell>
                              <TableCell
                                className="alinear-derecha"
                                sx={{ backgroundColor: data.color }}
                              >
                                {item?.numeroCupon || "-"}
                              </TableCell>
                              <TableCell
                                className="alinear-derecha"
                                sx={{ backgroundColor: data.color }}
                              >
                                {item?.codigoSorteo || "-"}
                              </TableCell>
                              <TableCell sx={{ backgroundColor: data.color }}>
                                {item.estado || "-"}
                              </TableCell>
                              <TableCell
                                className="alinear-centro"
                                sx={{ backgroundColor: data.color }}
                              >
                                {convertDate(item?.fechaCaducidad)}
                              </TableCell>
                              <TableCell
                                className="alinear-derecha"
                                sx={{ backgroundColor: data.color }}
                                align="right"
                              >
                                {formatCurrency(item.importePremio)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )),
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {openDetails ? (
        <ModalApuestas
          openDetails={openDetails}
          handleDetails={handleDetails}
          data={dataModal}
          filtro={filtro}
        />
      ) : null}
      {openPlay ? (
        <ModalJugadas
          openPlay={openPlay}
          handlePlay={handlePlay}
          data={dataModal}
        />
      ) : null}
    </Grid>
  );
};

export default Caducos;
