// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ComponentsError_container__TAad3 {
  width: 100%;
  height: 100%;
}

.ComponentsError_image__cf1Fs {
  animation: ComponentsError_init2__yyRCM 0.3s 0.5s both;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ComponentsError_image__cf1Fs img {
  width: 30%;
}

h4,
p {
  animation: ComponentsError_init__MeOgl 0.3s 0.6s both;
}

.ComponentsError_button__v3kkx {
  animation: ComponentsError_init__MeOgl 0.3s 0.6s both;
}

@keyframes ComponentsError_init__MeOgl {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ComponentsError_init2__yyRCM {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorPages/ComponentsError/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sDAA+B;EAC/B,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;;EAEE,qDAA8B;AAChC;;AAEA;EACE,qDAA8B;AAChC;;AAEA;EACE;IACE,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n}\n\n.image {\n  animation: init2 0.3s 0.5s both;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.image img {\n  width: 30%;\n}\n\nh4,\np {\n  animation: init 0.3s 0.6s both;\n}\n\n.button {\n  animation: init 0.3s 0.6s both;\n}\n\n@keyframes init {\n  0% {\n    opacity: 0;\n    transform: translateX(-20px);\n  }\n  100% {\n    opacity: 1;\n    transform: translateX(0);\n  }\n}\n\n@keyframes init2 {\n  0% {\n    opacity: 0;\n    transform: translateY(-20px);\n  }\n  100% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ComponentsError_container__TAad3`,
	"image": `ComponentsError_image__cf1Fs`,
	"init2": `ComponentsError_init2__yyRCM`,
	"init": `ComponentsError_init__MeOgl`,
	"button": `ComponentsError_button__v3kkx`
};
export default ___CSS_LOADER_EXPORT___;
