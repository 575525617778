import { useState, useEffect } from "react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import Tooltip from "@mui/material/Tooltip";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, Box } from "@mui/material";
import styles from "./index.module.css";
import Switch from "@mui/material/Switch";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import SettingsIcon from "@mui/icons-material/Settings";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../Shared/Loader/index.jsx";
import {
  getAllConfigurations,
  deleteConfigItem,
  updateConfigItem,
} from "../../../api/config/ConfigService";
import { SearchBar } from "../../Shared/SearchBar/SearchBar";
import { STATUS_OK } from "../../../config/strings";
import Typography from "@mui/material/Typography";
import { configKeysUpdate } from "../../../store/slices/localSlice";

export const Configure = () => {
  const [configItems, setConfigItems] = useState([]);
  const [originalConfigItems, setOriginalConfigItems] = useState([]);
  const [primaryColor, setPrimaryColor] = useState("");
  const { uiConfig, textColor } = useSelector((state) => state.local);
  const [edit, setEdit] = useState("");
  const [update, setUpdate] = useState("");
  const [valores, setValores] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(()=>{
    if (configItems.length === 0) {
    getAllConfigurations().then((response) => {
      console.log("load configs", response);
      if(originalConfigItems.length === 0){
        setOriginalConfigItems(response.content);
      }
      setConfigItems(response.content);
      setValores(
        response.content.sort((a, b) => a.id - b.id).map((con) => con.value),
      );
    });
  }}, []);
  

  useEffect(() => {
    setPrimaryColor(uiConfig.primary.main);
    dispatch(configKeysUpdate(configItems));
  }, [uiConfig, configItems, dispatch]);

  const handleEdit = (index) => {
    setEdit(index);
  };

  const handleDelete = (configItem) => {
    Swal.fire({
      title: `${t("areYouSureYouWantToDeleteTheKey")} ${configItem?.key}?`,
      text: `${t("ifYouEliminate ")} ${configItem?.key} ${t(" theChangeCannotBeReversed.")}`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("delete"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteConfigItem(configItem.id).then((response) => {
          const update = configItems.filter((p) => p.id !== configItem.id);
          if (response.status === STATUS_OK) {
            setConfigItems(update);
            Swal.fire(t("deleted"), t("keyRemovedSuccessfully"), "success");
          }
        });
      }
    });
  };

  const handleSave = (configItem, index) => {
    Swal.fire({
      title: `${t("doYouWantToSaveTheChangesTo")} ${configItem?.key}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("save"),
    }).then((result) => {
      if (result.isConfirmed) {
        const item = { ...configItem };
        item.value = valores[index];
        updateConfigItem(item).then((response) => {
          if (response.status === STATUS_OK) {
            const update = configItems.filter((p) => p.id !== configItem.id);
            update.push(item);
            setConfigItems(update);
            setEdit("");
            Swal.fire(t("modified"), t("succesfullModified"), "success");
          }
        });
      }
    });
  };

  const handleSwich = (e, configItem) => {
    configItem.value = e.target.checked.toString();

    const update = configItems.filter((u) => u.id !== configItem.id);
    setConfigItems([...update, configItem]);
  };

  const handleChangeText = (value, index) => {
    const arr = valores.map((con) => {
      const c = con;
      return c;
    });
    arr[index] = value;
    setValores(arr);
  };

  const renderValue = (configItem, index) => {
    if (configItem.value.toLowerCase() === "true") {
      return (
        <Switch
          disabled={edit}
          defaultChecked={true}
          onChange={(e) => handleSwich(e, configItem)}
        />
      );
    } else if (configItem.value.toLowerCase() === "false") {
      return (
        <Switch
          disabled={edit}
          defaultChecked={false}
          onChange={(e) => handleSwich(e, configItem)}
        />
      );
    } else {
      return (
        <TextField
          fullWidth
          disabled={index !== edit}
          id="textValue"
          size="small"
          defaultValue={configItem.value}
          variant="outlined"
          onChange={(e) => handleChangeText(e.target.value, index)}
          className={styles.textField}
        />
      );
    }
  };

  const newConfig = () => {
    let path = `./create`;
    navigate(path);
  };

  const handleOnSelect = (item) => {
    console.log(item);
    
    const filteredConfigItems = originalConfigItems.filter(
      (cItem) => cItem.id === item.id,
    );
    setConfigItems(filteredConfigItems);
  };

  const formatResult = (item) => {
    return (
      <div className={styles.msg_container}>
        <Typography
          sx={{
            display: "block",
            fontWeight: 600,
            fontSize: "19px",
            textTransform: "capitalize",
          }}
        >
          {item.key}
        </Typography>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 1,
        }}
      ></Grid>
      <Grid container className={styles.configureContainer}>
        <Grid sx={{ mb: 3 }} item className={styles.title}>
          <SettingsIcon
            color={"primary"}
            sx={{
              fontSize: "1.8rem",
              color: primaryColor,
            }}
          />
          <h1>{t("configure")}</h1>
          <div className={styles.createButton} onClick={newConfig}>
            <Box
              sx={{
                backgroundColor: "primary.main",
                color: textColor,
                width: "100%",
                height: "100%",
                borderRadius: 2,
              }}
              className={styles.btn}
            >
              {t("add")}
            </Box>
          </div>
          <Box sx={{ width: "70%" }}>
            <SearchBar
              arr={configItems}
              keys={{ keys: ["key"] }}
              result={"key"}
              onSelect={handleOnSelect}
              format={formatResult}
              primaryColor={uiConfig?.primary?.light}
              placeholder={t("enterASettingToSearch...")}
              component={"configuration"}
            />
          </Box>
        </Grid>

        <Grid sx={12} item className={styles.table}>
          {Array.isArray(configItems) && configItems.length ? (
            <>
              <TableContainer
                color={"primary"}
                className={styles.tableContainer}
              >
                <Table>
                  <TableHead>
                    <TableRow
                      color={primaryColor}
                      style={{
                        backgroundColor: primaryColor,
                        color: textColor,
                      }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          color: textColor,
                          fontSize: ".9rem",
                          fontWeight: 500,
                          fontFamily: "Roboto, sans-Serif",
                        }}
                        className={styles.tableCellHeader}
                      >
                        {t("Description")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: textColor,
                          fontSize: ".9rem",
                          fontWeight: 500,
                          fontFamily: "Roboto, sans-Serif",
                        }}
                        className={styles.tableCellHeader}
                      >
                        {t("value")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: textColor,
                          fontSize: ".9rem",
                          fontWeight: 500,
                          fontFamily: "Roboto, sans-Serif",
                        }}
                      >
                        {t("edit")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: textColor,
                          fontSize: ".9rem",
                          fontWeight: 500,
                          fontFamily: "Roboto, sans-Serif",
                        }}
                      >
                        {t("save")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: textColor,
                          fontSize: ".9rem",
                          fontWeight: 500,
                          fontFamily: "Roboto, sans-Serif",
                        }}
                      >
                        {t("delete")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(configItems) &&
                      [...configItems]
                        .sort((a, b) => a.id - b.id)
                        .map((configItem, index) => (
                          <TableRow key={index}>
                            <TableCell
                              align="left"
                              className={styles.tableCell}
                            >
                              {configItem.key}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={styles.tableCell}
                            >
                              {renderValue(configItem, index)}
                            </TableCell>
                            <TableCell
                              value={configItem.id}
                              align="center"
                              className={styles.tableCell}
                            >
                              <Tooltip title={t("editSettings")}>
                                <IconButton
                                  variant="outlined"
                                  onClick={() => handleEdit(index)}
                                >
                                  <EditIcon
                                    color={"primary"}
                                    sx={{
                                      fontSize: "1.8rem",
                                      cursor: "pointer",
                                      color: primaryColor,
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              value={configItem.id}
                              align="center"
                              className={styles.tableCell}
                            >
                              <Tooltip title={t("saveSettings")}>
                                <IconButton
                                  disabled={edit !== index ? true : false}
                                  variant="outlined"
                                  onClick={() => handleSave(configItem, index)}
                                >
                                  <SaveAsIcon
                                    color={"primary"}
                                    sx={{
                                      fontSize: "1.8rem",
                                      cursor: "pointer",
                                      color: primaryColor,
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              value={configItem.id}
                              align="center"
                              className={styles.tableCell}
                            >
                              <Tooltip title={t("deleteConfiguration")}>
                                <IconButton
                                  variant="outlined"
                                  onClick={() => handleDelete(configItem)}
                                >
                                  <DeleteForeverIcon
                                    color={"primary"}
                                    sx={{
                                      fontSize: "1.8rem",
                                      cursor: "pointer",
                                      color: primaryColor,
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Loader />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
