import React, { useEffect, useState } from "react";
import s from "./Modal.module.css";

import { useDispatch } from "react-redux";

//MaterialUi
import { Grid, Modal, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

import { alreadyAnalisis } from "../../../../store/slices/my_agencies.js";

function simulateAsync(time) {
  return new Promise((resolve) => {
    setTimeout(() => resolve("Usuario creado!"), time);
  });
}

const UsersModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const [analisis, setAnalisis] = useState(true);
  const [users, setUsers] = useState("0/4");

  async function simulateCreation() {
    for (let i = 1; i <= 4; i++) {
      console.log(`Creando usuario ${i}`);
      await simulateAsync(Math.random() * i * 1000);
      setUsers(`${i}/4`);
      console.log(`Creado con exito!`);
    }
    simulateAsync(500).then(() => {
      dispatch(alreadyAnalisis(false));
      Swal.fire({
        icon: "success",
        text: "Nuevos usuarios creados.",
        showConfirmButton: false,
        timer: 1000,
      });
      setOpen(false);
    });
  }

  useEffect(() => {
    console.log("Analizando usuarios para creación");
    simulateAsync(2500)
      .then(() => {
        setAnalisis(false);
      })
      .then(() => {
        simulateCreation();
      });
  }, []);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid container>
        <Grid
          xs={9}
          md={6}
          lg={4}
          item
          container
          className={s.boxModal}
          sx={{ bgcolor: "#EEEEEE" }}
        >
          <Grid
            container
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={"2rem"} color="primary" />
            {analisis ? (
              <p>Analizando usuarios</p>
            ) : (
              <p>{users} Generando usuarios para puntos de venta...</p>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default UsersModal;
