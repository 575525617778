import React from "react";
import s from "./Details.module.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Detail = ({ detail, setDetail }) => {
  const { textColor } = useSelector((state) => state.local);
  const { t } = useTranslation();

  return (
    <Grid container className={s.container}>
      <Grid item xs={12} className={s.header}>
        <Grid item container xs={12} className={s.titleContainer}>
          <Grid
            item
            xs={11}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <ArrowBackIcon
              sx={{ fontSize: "2rem", color: "primary.main", mr: 2 }}
              onClick={() => setDetail({ status: false, data: null })}
              className={s.arrowback}
            />
            <h4>{t("details")}</h4>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={11} className={s.name}>
        <div className={s.title}>
          <h4>{`${detail?.data?.firstname} ${detail?.data?.lastname}`}</h4>
          <Box
            sx={{ backgroundColor: "primary.main", color: textColor }}
            className={s.chip}
          >
            {t("user")}
          </Box>
        </div>
      </Grid>

      <Grid item container xs={11} className={s.data}>
        <Accordion
          defaultExpanded={true}
          className={s.accordion}
          sx={{ backgroundColor: "transparent", border: 0, boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: textColor }} />}
            sx={{ backgroundColor: "primary.main", borderRadius: "0 0 0 0" }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: 300,
                fontFamily: "Roboto, sans-serif",
                color: textColor,
              }}
            >
              {t("moreInformation")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={s.dataContainer}>
            <div className={s.item}>
              <h3>{t("active")}</h3>
              <p>{detail?.data?.enabled ? t("active") : t("inactive")}</p>
            </div>
            <div className={s.item}>
              <h3>{t("organization")}</h3>
              <p>{detail?.data?.memberships[0]?.organization?.name}</p>
            </div>
            <div className={s.item}>
              <h3>{t("mail")}</h3>
              <p>{detail?.data?.email}</p>
            </div>
            <div className={s.item}>
              <h3>{t("Roles")}</h3>
              {detail?.data?.memberships[0]?.roles?.map((r) => (
                <p>{r.name}</p>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default Detail;
