import apiClient from "../../utils/api.client";
import { getSaaId } from "../../utils/session.utils";

export const getGameOffers = async (organizationId, pageNumber) => {
  try {
    const response = await apiClient.get(`/gamma-game-service/organizations/${organizationId}/gameOffers?pageNumber=${pageNumber}&pageSize=100&orderCriteria=ASC&orderField=id`);
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error)
  }
}

export const getGamesSaa = async () => {
  try {
    const saaId = getSaaId();
    const response = await apiClient.get(`/saaServices/saaServices/services/site/juegos?organizacionId=${saaId}`);
    const data = response.data;
    console.log(data)

    return data;
  } catch (error) {
    console.error("Error at getGamesSaa EP: ", error);
    throw error;
  }
}
