import React, { useEffect, useState } from "react";
import s from "../ModalProviders/OrdersModal.module.css";
import styles from "../Ranking.module.css";

//Assets
import { calcularDiferenciaFechas } from "../../../../helpers/calculateDifDate";
import {
  CalendarioHoy,
  CalendarioSemanaPasada,
  PrimerDiaDelMes,
  primeroDeOctubre,
} from "../../../../helpers/today";
import { getHomologatedGamesFrom, System } from "../../../../helpers/games";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import "dayjs/locale/es";
import "dayjs/locale/pt";

//Material
import {
  Grid,
  Modal,
  TextField,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import { getHomologatedGames } from "../../../../api/general/generalService";
import { minDateRange } from "../../../../config/strings";
import { getGameOffers } from "../../../../api/games/GamesService";
import { getOrganizationId } from "../../../../utils/session.utils";
import { getGamesSaa } from "../../../../api/games/GamesService";

const ModalProviders = ({
  open,
  setOpen,
  filters,
  setFilters,
  setSearching,
  setSearch,
  setOrder,
  setLoading,
  inputFormat,
}) => {
  console.log(`Primer dia del mes: ${PrimerDiaDelMes()}`);
  const { t, i18n } = useTranslation();
  const [dateFrom, setDateFrom] = useState(
    new Date(CalendarioSemanaPasada()) > minDateRange
      ? CalendarioSemanaPasada()
      : minDateRange,
  );
  const [dateTo, setDateTo] = useState(CalendarioHoy());
  const { imputations } = useSelector((state) => state.gamma);
  const [gameSelected, setGameSelected] = useState(-1);
  const [orderSelected, setOrderSelected] = useState(1);
  const [desde, setDesde] = useState(new Date());
  const [hasta, setHasta] = useState(new Date());
  const [prevFilter, setPrevFilter] = useState({});
  const [homologated_Games, setHomologated_Games] = useState([]);
  const agencies = useSelector((state) => state.myAgencies.user_agencies);

  useEffect(() => {
    getGame();
  }, []);

  useEffect(() => {
    const dateFromFormat = JSON.stringify(dateFrom)
      .substring(0, 11)
      .split('"')[1];
    const dateToFormat = JSON.stringify(dateTo).substring(0, 11).split('"')[1];

    if (dateFrom && dateTo) {
      let diff = calcularDiferenciaFechas(dateFromFormat, dateToFormat);
      if (diff > 91) {
        setWrongDifDate(true);
        Swal.fire({
          title: t("wrongDates"),
          icon: "error",
        });
      } else {
        setWrongDifDate(false);
      }
    }
  }, [dateFrom, dateTo]);

  const handleChangeDateFrom = (date_picked) => {
    console.log(date_picked);
    setDesde(date_picked);
    setDateFrom(date_picked);
    setFilters((prevState) => ({
      ...prevState,
      from: `${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}/${
        date_picked.$M + 1 < 10 ? "0" : ""
      }${date_picked.$M + 1}/${date_picked.$y}`,
    }));
  };

  useEffect(() => {
    console.log(filters.from);
  }, [filters]);
  const handleChangeDateTo = (date_picked) => {
    console.log(date_picked);
    setHasta(date_picked);
    setDateTo(date_picked);
    setFilters((prevState) => ({
      ...prevState,
      to: `${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}/${
        date_picked.$M + 1 < 10 ? "0" : ""
      }${date_picked.$M + 1}/${date_picked.$y}`,
    }));
  };
  const handleGameChange = (event) => {
    setGameSelected(event.target.value);
    setFilters((prevState) => ({ ...prevState, game: event.target.value }));
  };
  const handleChangeOrder = (event) => {
    setOrderSelected(event.target.value);
    setOrder(event.target.value);
  };
  const handleMaquina = () => {
    setFilters({ ...filters, maquina: !filters.maquina });
  };
  const handleExit = () => {
    if (prevFilter !== filters) {
      setSearching(true);
      setSearch(true);
      setPrevFilter(filters);
    }
    setOpen(false);
  };

  const [wrongDifDate, setWrongDifDate] = useState(false);

  function getGame() {
    // getHomologatedGames()
    //   .then((r) =>
    //     r.filter(
    //       (juego) =>
    //         juego.integration[0].organization.id ===
    //           agencies[0].organization.id &&
    //         juego.integration[0].module === "SAA",
    //     ),
    //   )
    getGamesSaa()
      .then((res) => {
        // res.sort((a, b) => {
        //   const nameA = a.name.toUpperCase();
        //   const nameB = b.name.toUpperCase();
        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   if (nameA > nameB) {
        //     return 1;
        //   }
        //   return 0;
        // });
        setHomologated_Games(res);
      })
      .catch((error) => console.log("Error"));
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid container>
        <Grid
          xs={12}
          md={8}
          lg={4.5}
          item
          container
          className={s.boxModal}
          sx={{ bgcolor: "#EEEEEE" }}
        >
          <Grid item xs={10} className={s.exit}>
            <div className={s.titleModal}>
              <p>{t("filter")}</p>
              <span>{t("filterConfig")}</span>
            </div>
            <CloseIcon
              onClick={() => setOpen(false)}
              className={s.exitIcon}
              sx={{ color: "primary.dark" }}
            />
          </Grid>
          <Grid container xs={10} sx={{ mt: 3 }}>
            <Grid container spacing={1}>
              <Grid sx={{ fontSize: "1rem", marginBottom: 1 }} item xs={12}>
                {t("date")}
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                  className={styles.superdate}
                >
                  <DatePicker
                    label={t("from")}
                    inputFormat="DD/MM/YYYY"
                    value={desde}
                    onChange={handleChangeDateFrom}
                    renderInput={(params) => <TextField {...params} />}
                    className={styles.date}
                    minDate={primeroDeOctubre()}
                    maxDate={hasta}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                  className={styles.superdate}
                >
                  <DatePicker
                    label={t("to")}
                    inputFormat={inputFormat}
                    value={dateTo}
                    onChange={(date) => handleChangeDateTo(date)}
                    renderInput={(params) => <TextField {...params} />}
                    className={styles.date}
                    minDate={desde}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid sx={{ marginTop: 3 }} item xs={6}>
              <Box sx={{ fontSize: "1rem", marginBottom: 1 }}>{t("game")}</Box>
              <Box sx={{ minWidth: 120 }}>
                <FormControl sx={{ width: "95%" }} size="small">
                  <Select
                    value={gameSelected}
                    displayEmpty
                    onChange={handleGameChange}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem selected value="-1">
                      <em>{t("all")}</em>
                    </MenuItem>
                    {Array.isArray(homologated_Games) && 
                     homologated_Games
                      ?.map((item) => (
                        <MenuItem key={item?.juego} value={item?.juego}>
                          {item?.descripcionJuego}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Grid sx={{ mt: 2 }}>
                <div className={s.switch}>
                  <p style={{ fontSize: "1rem" }}>
                    {t("machineWithMovements")}
                  </p>
                  <Switch onChange={handleMaquina} checked={filters?.maquina} />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "not-allowed",
            }}
          >
            <Button
              variant="contained"
              disabled={
                (filters.agencieName === "" && filters.agencieNumber === 0) ||
                wrongDifDate
              }
              onClick={() => {
                handleExit();
              }}
            >
              {t("apply")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalProviders;
