import "./index.css";
import React from "react";
import { ConvertColorText } from "../../../helpers/ConvertColorText";
import { useSelector } from "react-redux";

export const Main = () => {
  const { uiConfig } = useSelector((state) => state.local);
  ConvertColorText(uiConfig.primary.main);

  return (
    <div className="container">
      <div className="indicator">
        <h1>Pantalla principal</h1>
      </div>
    </div>
  );
};
