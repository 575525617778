import { useState, useEffect } from "react";
import React from "react";
import {
  Grid,
  Modal,
  TextField,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import { primeroDeOctubre } from "../../../../helpers/today";
import styles from "../index.module.css";
import { useSelector } from "react-redux";
import s from "../ModalProviders/OrdersModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MovementsByImputation } from "../../../../api/accounting/accountingHelper";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { calcularDiferenciaFechas } from "../../../../helpers/calculateDifDate";
import "dayjs/locale/es";
import "dayjs/locale/pt";
import { minDateRange } from "../../../../config/strings";
import { getImputation } from "../../../../api/accounting/imputation";
import { getOrganizationId } from "../../../../utils/session.utils";
const ModalProviders = ({
  open,
  setOpen,
  filters,
  setFilters,
  setSearching,
  movements,
  setSearch,
  cuentaCorriente,
  setSearchDay,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  diaHoy,
}) => {
  const { imputations } = useSelector((state) => state.gamma);
  const { t, i18n } = useTranslation();
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult,
  );
  const [gameSelected, setGameSelected] = useState(-1);
  const [conceptSelected, setConceptSelected] = useState("");
  const [homologated_Games, setHomologated_Games] = useState([]);
  const [desde, setDesde] = useState(new Date());
  const [hasta, setHasta] = useState(() => new Date());
  const [prevDateFrom, setPrevDateFrom] = useState(filters.dateFrom);
  const [prevDateTo, setPrevDateTo] = useState(filters.dateTo);
  const agencies = useSelector((state) => state.myAgencies.user_agencies);
  const handleChangeDateFrom = (date_picked) => {
    setDateFrom(date_picked);
    // setFilters((prevState) => ({
    //   ...prevState,
    //   dateFrom: `${date_picked.$y}-${date_picked.$M + 1 < 10 ? "0" : ""}${
    //     date_picked.$M + 1
    //   }-${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}T${
    //     date_picked.$H < 10 ? "0" : ""
    //   }${date_picked.$H}:${date_picked.$m < 10 ? "0" : ""}${date_picked.$m}:${
    //     date_picked.$s < 10 ? "0" : ""
    //   }${date_picked.$s}`,
    // }));
  };
  const handleChangeDateTo = (date_picked) => {
    setDateTo(date_picked);
    // setFilters((prevState) => ({
    //   ...prevState,
    //   dateTo: `${date_picked.$y}-${date_picked.$M + 1 < 10 ? "0" : ""}${
    //     date_picked.$M + 1
    //   }-${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}T${
    //     date_picked.$H < 10 ? "0" : ""
    //   }${date_picked.$H}:${date_picked.$m < 10 ? "0" : ""}${date_picked.$m}:${
    //     date_picked.$s < 10 ? "0" : ""
    //   }${date_picked.$s}`,
    // }));
  };

  const handleGameChange = (event) => {
    setGameSelected(event.target.value);  
    setFilters((prevState) => ({ ...prevState, game: event.target.value }));
  };

  const handleConceptChange = (event) => {
    setConceptSelected(event.target.value);
    setFilters((prevState) => ({ ...prevState, concept: event.target.value }));
  };

  const [wrongDifDate, setWrongDifDate] = useState(false);

  useEffect(() => {
    getGame();
  }, []);

  useEffect(() => {
    // const dateFromFormat = JSON.stringify(filters?.dateFrom)
    //   .substring(0, 11)
    //   .split('"')[1];
    // const dateToFormat = JSON.stringify(filters?.dateTo)
    //   .substring(0, 11)
    //   .split('"')[1];

    // if (filters?.dateFrom && filters?.dateTo) {
    //   let diff = calcularDiferenciaFechas(dateFromFormat, dateToFormat);
    //   if (diff > 91) {
    //     setWrongDifDate(true);
    //     Swal.fire({
    //       title: t("wrongDates"),
    //       icon: "error",
    //     });
    //   } else {
    //     setWrongDifDate(false);
    //   }
    // }
    const dateFromFormat = JSON.stringify(dateFrom)
      .substring(0, 11)
      .split('"')[1];
    const dateToFormat = JSON.stringify(dateTo)
      .substring(0, 11)
      .split('"')[1];

      console.log("dateFromFormat");
      console.log(dateFromFormat);
      console.log("dateToFormat");
      console.log(dateToFormat);

    if (dateFrom && dateTo) {
      let diff = calcularDiferenciaFechas(dateFromFormat, dateToFormat);
      if (diff > 91) {
        setWrongDifDate(true);
        Swal.fire({
          title: t("wrongDates"),
          icon: "error",
        });
      } else {
        setWrongDifDate(false);
      }
    }
  }, [dateFrom, dateTo]);

  const buscarConceptos = () => {
    if (cuentaCorriente?.movements?.length > 0) {
      let gameMovements = cuentaCorriente.movements.filter((item) =>
        filters?.game !== -1 ? item.gameId === filters?.game : item,
      );
      let conceptos = gameMovements.map((mov) => {
        return [mov.description, mov];
      });
      conceptos = conceptos.sort((a, b) => {
        const nameA = a[0].toUpperCase();
        const nameB = b[0].toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return [...new Map(conceptos).values()];
    }
    return null;
  };

  function buscar() {

    if (dateTo.toString().length < 29 && dateFrom.toString().length < 29){

      setFilters((prevState) => ({
        ...prevState,
        dateFrom: dateFrom,
        dateTo: dateTo,
      }));

    }else{

    if (dateTo.toString().length < 29){
      setFilters((prevState) => ({
        ...prevState,
        dateFrom: `${dateFrom.$y}-${dateFrom.$M + 1 < 10 ? "0" : ""}${
          dateFrom.$M + 1
        }-${dateFrom.$D < 10 ? "0" : ""}${dateFrom.$D}T${
          dateFrom.$H < 10 ? "0" : ""
        }${dateFrom.$H}:${dateFrom.$m < 10 ? "0" : ""}${dateFrom.$m}:${
          dateFrom.$s < 10 ? "0" : ""
        }${dateFrom.$s}`,
        dateTo: dateTo,
      }));

    }else if (dateFrom.toString().length < 29){
      setFilters((prevState) => ({
        ...prevState,
        dateFrom: dateFrom,
        dateTo: `${dateTo.$y}-${dateTo.$M + 1 < 10 ? "0" : ""}${
          dateTo.$M + 1
        }-${dateTo.$D < 10 ? "0" : ""}${dateTo.$D}T${
          dateTo.$H < 10 ? "0" : ""
        }${dateTo.$H}:${dateTo.$m < 10 ? "0" : ""}${dateTo.$m}:${
          dateTo.$s < 10 ? "0" : ""
        }${dateTo.$s}`,
      }));

    }else {

    setFilters((prevState) => ({
      ...prevState,
      dateFrom: `${dateFrom.$y}-${dateFrom.$M + 1 < 10 ? "0" : ""}${
        dateFrom.$M + 1
      }-${dateFrom.$D < 10 ? "0" : ""}${dateFrom.$D}T${
        dateFrom.$H < 10 ? "0" : ""
      }${dateFrom.$H}:${dateFrom.$m < 10 ? "0" : ""}${dateFrom.$m}:${
        dateFrom.$s < 10 ? "0" : ""
      }${dateFrom.$s}`,
      dateTo: `${dateTo.$y}-${dateTo.$M + 1 < 10 ? "0" : ""}${
        dateTo.$M + 1
      }-${dateTo.$D < 10 ? "0" : ""}${dateTo.$D}T${
        dateTo.$H < 10 ? "0" : ""
      }${dateTo.$H}:${dateTo.$m < 10 ? "0" : ""}${dateTo.$m}:${
        dateTo.$s < 10 ? "0" : ""
      }${dateTo.$s}`,
    }));
    }
    }
    

    if(wrongDifDate === false) {

    setOpen(false);
    setSearching(true);
    if (prevDateFrom !== dateFrom || prevDateTo !== dateTo) {
      setSearch(true);
      setSearchDay(true);
    } else {
      setPrevDateFrom(dateFrom);
      setPrevDateTo(dateTo);
    }
    }
  }

  function getGame() {
    // getHomologatedGames()
      // .then((r) =>
        // r.filter(
          // (juego) =>
            // juego?.integration[0]?.organization.id ===
            // agencies[0]?.organization?.id &&
            // juego?.integration[0]?.module === "SAA"
        // )
      // )
      getImputation()
      .then((res) => {
        // res.sort((a, b) => {
        //   const nameA = a.name.toUpperCase();
        //   const nameB = b.name.toUpperCase();
        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   if (nameA > nameB) {
        //     return 1;
        //   }
        //   return 0;
        // });        
        setHomologated_Games(res?.content);
      })
      .catch((error) => console.log("Error"));
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid container>
        <Grid
          xs={12}
          md={8}
          lg={4.5}
          item
          container
          className={s.boxModal}
          sx={{ bgcolor: "#EEEEEE" }}
        >
          <Grid item xs={10} className={s.exit}>
            <div className={s.titleModal}>
              <p>{t("filter")}</p>
              <span>{t("filterConfig")}</span>
            </div>
            <CloseIcon
              onClick={() => setOpen(false)}
              className={s.exitIcon}
              sx={{ color: "primary.dark" }}
            />
          </Grid>

          <Grid
            item
            xs={11}
            container
            spacing={1}
            sx={{ mt: 3, justifyContent: "center" }}
          >
            <Grid sx={{ fontSize: "1rem", marginBottom: 1 }} item xs={11}>
              {t("filterByDate")}
            </Grid>
            <Grid
              item
              container
              xs={11}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item xs={5.5}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                  className={styles.superdate}
                >
                  <DatePicker
                    label={t("from")}
                    inputFormat="DD/MM/YYYY"
                    inputProps={{ readOnly: true }}
                    value={dateFrom}
                    onChange={(date) => handleChangeDateFrom(date)}
                    renderInput={(params) => <TextField {...params} />}
                    className={styles.date}
                    maxDate={dateTo}
                    minDate={primeroDeOctubre()}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={5.5}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                  className={styles.superdate}
                >
                  <DatePicker
                    label={t("to")}
                    inputFormat="DD/MM/YYYY"
                    inputProps={{ readOnly: true }}
                    value={dateTo}
                    onChange={(date) => handleChangeDateTo(date)}
                    renderInput={(params) => <TextField {...params} />}
                    className={styles.date}
                    minDate={dateFrom}
                    maxDate={diaHoy}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={11}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Grid sx={{ marginTop: 2 }} item xs={5.5}>
                <Box sx={{ fontSize: "1rem", marginBottom: 1 }}>
                  {t("imputation")}
                </Box>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      value={gameSelected}
                      displayEmpty
                      onChange={handleGameChange}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={-1}>
                        <em>{t("all")}</em>
                      </MenuItem>
                      {Array.isArray(homologated_Games) &&
                        homologated_Games?.map((item) => (
                          // <MenuItem key={item.id} value={item.id}>
                          <MenuItem key={item?.id} value={item?.id}>
                            {/* {item.name}
                         </MenuItem>
                         <MenuItem key={item.id} value={item.modality.id}> */}
                           {item?.name}
                        </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid sx={{ marginTop: 2 }} item xs={5.5}>
                <Box sx={{ fontSize: "1rem", marginBottom: 1 }}>
                  {t("concept")}
                </Box>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      value={conceptSelected}
                      onChange={handleConceptChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        <em>{t("all")}</em>
                      </MenuItem>
                      {buscarConceptos() !== null &&
                        buscarConceptos().map((concepto, i) => (
                          <MenuItem value={concepto.description}>
                            {concepto.description}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: "30px",
              cursor: "not-allowed",
            }}
          >
            <Button
              variant="contained"
              disabled={
                (filters.agencieName === "" && filters.agencieNumber === 0) ||
                agencieSelected === {} ||
                wrongDifDate
              }
              onClick={() => buscar()}
            >
              {t("apply")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalProviders;
