export const dateInRange = (dateFrom, dateTo, date) => {
  let d1 = dateFrom?.split("/");
  let d2 = dateTo?.split("/");
  let c = date?.split("/");

  let from = new Date(dateFrom && d1[2], parseInt(d1[1]) - 1, d1[0]);
  let to = new Date(dateTo && d2[2], parseInt(d2[1]) - 1, d2[0]);
  let check = new Date(date && c[2], date && parseInt(c[1]) - 1, date && c[0]);

  console.log(check > from && check < to);
  return check >= from && check <= to;
};
