import React from "react";
import styles from "./index.module.css";
import Grid from "@mui/material/Grid";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  callMovementsById,
  clearMyAgencies,
} from "../../store/slices/my_agencies.js";
import argentinaFlag from "../../assets/argentina-flag.png";
import brazilFlag from "../../assets/brazil-falg.png";
import { languages } from "../../config/strings";
import { updateLanguage } from "../../store/slices/localSlice";
import { movementsUpdate } from "../../store/slices/gammaSlice";
import { Box } from "@mui/system";
import Divider from "@mui/material/Divider";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { setSelectedAgencieToConsult } from "../../store/slices/my_agencies";
import { useNavigate, useLocation } from "react-router-dom";
import { LogoLoader } from "../Shared/SkeletonLoaders";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";

export const Header = () => {
  const [logo, setLogo] = useState("");
  const { currentLogo } = useSelector((state) => state.local);
  const { language } = useSelector((state) => state.local);
  const dispatch = useDispatch();
  const agencies = useSelector((state) => state.myAgencies.user_agencies);
  const { logged_user } = useSelector((state) => state?.gamma);
  const [consultingAgencie, setConsultingAgencie] = useState({});
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult,
  );
  const concentrator = useSelector(
    (state) => state.myAgencies?.selected_concentrator,
  );
  const [subagencies, setSubagencies] = useState();
  // const { agencies_favs } = useSelector((state) => state?.myAgencies)
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const [Agency, setAgency] = useState(null);
  const [showModal, setShowModal] = useState();

  const posiblePaths = ["cuenta-corriente", "premios", "control-caja"];

  useEffect(() => {
    setSubagencies(concentrator?.childrenAgencies);
  }, [agencieSelected]);

  // const handleConsulting = (agencie) => {
  //   if(JSON.stringify(agencie) === JSON.stringify(consultingAgencie)){
  //     setConsultingAgencie(false)
  //     dispatch(setSelectedAgencieToConsult({}))
  //     dispatch(movementsUpdate([]))
  //   }else{
  //     setConsultingAgencie(agencie)
  //     dispatch(setSelectedAgencieToConsult(agencie))
  //     dispatch(movementsUpdate([]))
  //     for (const pat of posiblePaths) {
  //       if (location.pathname.includes(pat)) {
  //         if (agencie?.agencyLevel === 'CONCENTRATOR') {
  //           navigate(`${pat}/${agencie?.number}`)
  //         }
  //         else{
  //           navigate(`${pat}/${agencie?.number}-${agencie?.subNumber || 0}`)
  //         }
  //       }
  //     }
  //   }
  // }

  useEffect(() => {
    setLogo(currentLogo?.imgBase64);
  }, [currentLogo]);

  useEffect(() => {
    setConsultingAgencie(agencieSelected);
  }, [agencieSelected]);

  // const changeAgencie = (value) =>{
  //   dispatch(setSelectedAgencieToConsult(value.target.value))
  // }

  const changeLanguage = (e) => {
    dispatch(updateLanguage(e.target.value));
    Swal.fire({
      icon: "success",
      title: `${t("selectedLanguage")}: ${e.target.value === "es" ? "Español" : "Português"}`,
      showConfirmButton: false,
      timer: 1200,
    });
  };

  // function showHide(){
  //   setShowModal(!showModal)
  // }

  // useEffect(()=>{
  //   if(Agency){
  //     handleConsulting(Agency)
  //   }
  // },[Agency])

  return (
    <Grid container className={styles.container}>
      <Grid item xs={7} md={9} className={styles.logo}>
        {logo?.length ? (
          <img className={styles.headerLogo} src={logo} alt="logo" />
        ) : (
          <LogoLoader />
        )}
      </Grid>
      <Grid className={styles.flags} item xs={5} md={3}>
        {/* <Tooltip title={t('notifications1')}>
        <CircleNotificationsIcon
            color="primary"
            sx={{fontSize: '3rem', mr: 2}}
          ></CircleNotificationsIcon>
      </Tooltip>
           */}

        <Select
          labelId="select-label"
          id="select"
          value={language}
          onChange={changeLanguage}
        >
          <MenuItem value={languages.es}>
            <img
              className={`${styles.flagIcon}`}
              src={argentinaFlag}
              alt="Español"
            />
            {t("spanish")}
          </MenuItem>
          <MenuItem value={languages.pt}>
            <img
              className={`${styles.flagIcon}`}
              src={brazilFlag}
              alt="Portugues"
            />
            {t("portuguese")}
          </MenuItem>
        </Select>
      </Grid>

      {location.pathname.includes("main") && (
        <Grid item xs={11} display="flex" alignItems="center">
          {/* <Box className={styles.agencyTitle} display="inline">{t('agency')} {Object.values(consultingAgencie)?.length ? `${consultingAgencie?.number} - ${consultingAgencie?.subNumber || 0}` : ""}</Box>
          <Divider sx={{mx: 2}} orientation="vertical" flexItem /> */}

          {/* <Box sx={{border: "solid", borderWidth: 1, padding: 1, marginLeft: 1, borderColor: "gray", borderRadius: 2, width: 250}}>
          {Array.isArray(agencies_favs) && agencies_favs?.length ? agencies_favs?.map(agencie => 
            <Chip key={agencie?.id} sx={{ m: 0.5, width: 75, '& .MuiChip-label': { fontSize: "1.1rem" }}} size="small" clickable label={`${agencie?.number} - ${agencie?.subNumber || 0}`} color={consultingAgencie?.id === agencie?.id ? "secondary" :"primary"} onClick={() => handleConsulting(agencie)}/>    
          ) : <></>}
            <Chip onClick={() => navigate('agencias')} sx={{ m: 0.5, width: 75, '& .MuiChip-label': { fontSize: "0.9rem" }}} size="small" variant="outlined" clickable label={t('seeMore')} color="primary" />  
          </Box> */}

          {/* <Box>
            <Select 
              labelId="select-label"
              id="select"
              sx={{ borderColor: "black", ":hover": `borderColor: black`, height: '55px', fontSize: '12px'}}

            >
              {
                console.log("subAgencias", subAgencies)
              }
              <MenuItem value={"0"}>{"Seleccionar agencia"}</MenuItem>
              {
                Array.isArray(subAgencies) ? 
                subAgencies?.map((item, i) => 
                <MenuItem key={i} value={item.name}>{item.name}</MenuItem>)
                : null
              } 
              
            </Select>
          </Box> */}
          {/* 
          { (location.pathname.includes("cuenta-corriente") || location.pathname.includes("premios") || location.pathname.includes("control-caja") ) &&
            <Grid className={styles.selectAgency} item xs={12} >
            <p>{Agency ? `Agencia Seleccionada: ${Agency.name}` : t('agencyNotSelected')}</p>
            <Button
              onClick={showHide}
              color="primary" sx={{ fontSize: 15 }}
              variant="contained" >
              {t('selectAgency')}
            </Button>
            {showModal && <AgencyModal open={showModal} setOpen={setShowModal} onSelect={setAgency} />}
          </Grid>} */}
        </Grid>
      )}
    </Grid>
  );
};
