import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { MuiColorInput } from "mui-color-input";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/system";
import {
  Avatar,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
  Tooltip,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { uiConfigUpdate, addPalette } from "../../../store/slices/localSlice";
import { ConvertColorText } from "../../../helpers/ConvertColorText";
import BrushIcon from "@mui/icons-material/Brush";
import IconButton from "@mui/material/IconButton";
import { ColorsChangePage } from "../../../store/slices/paginationSlice";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getAllThemes,
  setCurrentTheme,
  setDefaultTheme,
  addNewTheme,
  editTheme,
  deleteTheme,
  currentTheme,
} from "../../../api/themes/ThemeService";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { parseDate, convertDate } from "../../../helpers/convertDate";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

export const Colors = () => {
  const [primaryColor, setPrimaryColor] = useState("");
  const { uiConfig } = useSelector((state) => state.local);
  const [Themes, setThemes] = useState([]);
  const [paletteName, setPaletteName] = useState(uiConfig.title);
  const [primaryColorCustom, setPrimaryColorCustom] = useState(
    uiConfig.primary.main,
  );
  const [primaryColorLightCustom, setPrimaryColorLightCustom] = useState(
    uiConfig.primary.light,
  );
  const [primaryColorDarkCustom, setPrimaryColoDarkrCustom] = useState(
    uiConfig.primary.dark,
  );
  const [secondaryColorCustom, setSecondadryColorCustom] = useState(
    uiConfig.secondary.main,
  );
  const [secondaryColorLightCustom, setSecondadryColorLightCustom] = useState(
    uiConfig.secondary.light,
  );
  const [secondaryColorDarkCustom, setSecondadryColorDarkCustom] = useState(
    uiConfig.secondary.dark,
  );
  const [colorPrimaryData, setColorPrimaryData] = useState(
    uiConfig.primary.main,
  );
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [editId, setEditId] = useState(null);
  const [editDefaultStatus, setEditDefaultStatus] = useState(false);
  const [activeByDate, setActiveByDate] = useState({});
  const { t } = useTranslation();
  const { textColor } = useSelector((state) => state.local);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.gamma.logged_user);

  const [display, setDisplay] = useState("list");

  let today = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000,
  )
    .toISOString()
    .split("T")[0];

  useEffect(() => {
    setPrimaryColor(uiConfig.primary.main);
    dispatch(ColorsChangePage(1));
  }, [dispatch, uiConfig.primary.main, Themes]);

  ConvertColorText(colorPrimaryData);

  const handleClose = () => {
    setPaletteName("");
    setOpenModal(false);
    setEditId(null);
  };

  useEffect(() => {
    currentTheme(currentUser?.memberships[0]?.organization?.id).then((res) =>
      setActiveByDate(res),
    );
  }, []);

  const getCurrentTheme = () => {
    currentTheme(currentUser?.memberships[0]?.organization.id)
      .then((currentSelectedTheme) => {
        const themeColors = {
          id: currentSelectedTheme.id,
          title: currentSelectedTheme.displayName,
          primary: {
            main: currentSelectedTheme.primary.normal,
            light: currentSelectedTheme.primary.light,
            dark: currentSelectedTheme.primary.dark,
          },
          secondary: {
            main: currentSelectedTheme.secondary.normal,
            light: currentSelectedTheme.secondary.light,
            dark: currentSelectedTheme.secondary.dark,
          },
          custom: uiConfig.custom.length ? [...uiConfig.custom] : [],
        };
        dispatch(uiConfigUpdate(themeColors));
        setActiveByDate(currentSelectedTheme);
        getAllThemes().then((response) => {
          setThemes(response.sort((a, b) => a.id - b.id));
        });
      })
      .catch((e) => {
        dispatch(uiConfigUpdate(uiConfig.colors));
      });
  };

  function formatDate(string) {
    let date = new Date(string);
    return date.toISOString().split("T")[0];
  }

  if (!Themes?.length) {
    getAllThemes().then((response) => {
      setThemes(response.sort((a, b) => a.id - b.id));
    });
  }

  const selectDefaultTheme = (item, i) => {
    //default
    Swal.fire({
      title: t("setDefault"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#4BB544",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
      backdrop: "none",
    }).then((result) => {
      if (result.isConfirmed) {
        setDefaultTheme(item.id, currentUser?.memberships[0]?.organization.id)
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Selecciono Paleta por Defecto con éxito",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .then(() => {
            getAllThemes()
              .then((response) => {
                setThemes(response.sort((a, b) => a.id - b.id));
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const addCustomPalette = () => {
    const customPaletteColors = {
      displayName: paletteName,
      primary: {
        dark: primaryColorDarkCustom,
        light: primaryColorLightCustom,
        normal: primaryColorCustom,
      },
      secondary: {
        dark: secondaryColorDarkCustom,
        light: secondaryColorLightCustom,
        normal: secondaryColorCustom,
      },
      custom: {
        custom: true,
        ownerId: null,
        defaultP: false,
        validFrom: fromDate,
        validTo: toDate,
        organizationId: currentUser?.memberships[0]?.organization.id,
      },
    };

    addNewTheme(customPaletteColors)
      .then((response) => {
        let newThemes = [...uiConfig, response];
        dispatch(uiConfigUpdate(newThemes));
        Swal.fire({
          icon: "success",
          title: "Cambios guardados con éxito",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .then(() => {
        getCurrentTheme();
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch(addPalette(customPaletteColors));
    let themesUpdate = [...Themes, customPaletteColors];
    // dispatch(ThemesUpdate(themesUpdate));
    setThemes(themesUpdate);
    setOpenModal(false);
  };

  const handleEditTheme = () => {
    const editedPaletteColors = {
      id: editId,
      displayName: paletteName,
      primary: {
        dark: primaryColorDarkCustom,
        light: primaryColorLightCustom,
        normal: primaryColorCustom,
      },
      secondary: {
        dark: secondaryColorDarkCustom,
        light: secondaryColorLightCustom,
        normal: secondaryColorCustom,
      },
      custom: {
        custom: true,
        ownerId: null,
        defaultP: editDefaultStatus,
        validFrom: fromDate,
        validTo: toDate,
        organizationId: currentUser?.memberships[0]?.organization.id,
      },
    };

    editTheme(editedPaletteColors)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Cambios guardados con éxito",
          showConfirmButton: false,
          timer: 1500,
        });
        getCurrentTheme();
        setThemes(response);
        setOpenModal(false);
        setEditId(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeTheme = (theme) => {
    const selectedTheme = Themes.filter((f) => f.id !== theme.id * 1);
    // dispatch(ThemesUpdate(selectedTheme));
    setThemes(selectedTheme);
  };
  const deleteSelectedTheme = (item) => {
    Swal.fire({
      title: t("deletePalette"),
      text: t("deletePaletteMessage"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: primaryColor,
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTheme(item.id).then((response) => {
          removeTheme(item);
          getCurrentTheme();
        });
      }
    });
  };

  const cellStyles = {
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.9rem",
    fontWeight: "400",
    borderBottom: "1px solid rgba(0, 0, 0, 0.455)",
  };

  return (
    <Grid container className={styles.container}>
      <Grid
        item
        container
        lg={11}
        md={11}
        xs={11}
        className={styles.title}
        mt={3}
      >
        <Grid item container lg={4} md={5} xs={11}>
          <IconButton>
            <BrushIcon
              color={"primary"}
              sx={{
                fontSize: "1.8rem",
                color: primaryColor,
                cursor: "pointer",
              }}
            />
          </IconButton>
          {t("colors")}
          <Grid item lg={2} ml={2}>
            <Button
              variant="contained"
              onClick={() => {
                setOpenModal(true);
                setPaletteName("");
                setFromDate("");
                setToDate("");
              }}
            >
              {t("add")}
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          lg={1}
          md={2}
          xs={11}
          sx={{ display: "flex", justifyContent: "flex-start", ml: 2 }}
        >
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={display}
            onChange={(e, v) => setDisplay(v)}
          >
            <ToggleButton value="list">
              <ViewListIcon
                sx={{ fontSize: "1.3rem", color: "primary.main" }}
              />
            </ToggleButton>
            <ToggleButton value="module">
              <ViewModuleIcon
                sx={{ fontSize: "1.3rem", color: "primary.main" }}
              />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      {Themes.length ? (
        display === "module" ? (
          <>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="center"
              mt={5}
            >
              {Themes?.map(
                (item, i) =>
                  item.custom.custom === false && (
                    <Grid
                      sx={{ m: 1, borderColor: "secondary.main" }}
                      key={i}
                      className={`${styles.cardColor} ${item.displayName === uiConfig.title ? styles.cardColorSelected : null}`}
                      xs={10}
                      sm={5}
                      lg={3.5}
                    >
                      <h4>{item.displayName}</h4>
                      <Grid container item>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            backgroundColor: item.primary.normal,
                            width: "50%",
                            height: 115,
                          }}
                        ></Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            backgroundColor: item.secondary.normal,
                            Width: "50%",
                            height: 115,
                          }}
                        ></Grid>
                        <Grid container item>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              backgroundColor: item.primary.light,
                              width: "25%",
                              height: 100,
                            }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              backgroundColor: item.primary.dark,
                              width: "25%",
                              height: 100,
                            }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              backgroundColor: item.secondary.light,
                              width: "25%",
                              height: 100,
                            }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              backgroundColor: item.secondary.dark,
                              width: "25%",
                              height: 100,
                            }}
                          ></Grid>
                        </Grid>
                      </Grid>
                      <Button
                        onClick={() => selectDefaultTheme(item, i)}
                        sx={{ mt: 1 }}
                        variant="contained"
                        disabled={
                          item.id === uiConfig.id || item.custom.defaultP
                        }
                      >
                        {t("selectDefault")}
                      </Button>
                      <Grid item xs={12}>
                        {item?.id === activeByDate?.id &&
                        item?.custom?.custom ? (
                          <Chip
                            icon={<AccessTimeIcon />}
                            color={"primary"}
                            label={t("activeByDate")}
                            sx={{ mt: 3 }}
                          />
                        ) : item.custom.defaultP ? (
                          <Chip
                            icon={<PushPinRoundedIcon />}
                            color={"primary"}
                            label={t("standardPalette")}
                            sx={{ mt: 3 }}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  ),
              )}

              {Themes?.map((item, i) => {
                return (
                  item.custom.custom === true && (
                    <Grid
                      sx={{ m: 1, borderColor: "primary.main" }}
                      key={i}
                      className={`${styles.cardColor} ${item.displayName === uiConfig.title ? styles.cardColorSelected : null}`}
                      xs={10}
                      sm={5}
                      lg={3.5}
                    >
                      <h4>
                        {item.displayName}
                        <Box
                          sx={{
                            fontSize: "0.8rem",
                            fontWeight: 600,
                            display: "inline",
                            mt: 0.5,
                          }}
                          className={styles.itemDate}
                        >
                          ({item.custom.validFrom.slice(0, 10)} al{" "}
                          {item.custom.validTo.slice(0, 10)})
                        </Box>
                      </h4>
                      <Grid container item>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            backgroundColor: item.primary.normal,
                            width: "50%",
                            height: 100,
                          }}
                        ></Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            backgroundColor: item.secondary.normal,
                            Width: "50%",
                            height: 100,
                          }}
                        ></Grid>
                        <Grid container item>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              backgroundColor: item.primary.light,
                              width: "25%",
                              height: 100,
                            }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              backgroundColor: item.primary.dark,
                              width: "25%",
                              height: 100,
                            }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              backgroundColor: item.secondary.light,
                              width: "25%",
                              height: 100,
                            }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              backgroundColor: item.secondary.dark,
                              width: "25%",
                              height: 100,
                            }}
                          ></Grid>
                        </Grid>
                      </Grid>
                      <Button
                        onClick={() => deleteSelectedTheme(item)}
                        sx={{ mt: 1, mr: 1 }}
                        variant="contained"
                      >
                        {t("delete")}
                      </Button>
                      <Button
                        sx={{ mt: 1, mr: 1 }}
                        variant="contained"
                        onClick={() => {
                          setOpenModal(true);
                          setPaletteName(item.displayName);
                          setFromDate(item.custom.validFrom);
                          setToDate(item.custom.validTo);
                          setPrimaryColorCustom(item.primary.normal);
                          setPrimaryColorLightCustom(item.primary.light);
                          setPrimaryColoDarkrCustom(item.primary.dark);
                          setSecondadryColorCustom(item.secondary.normal);
                          setSecondadryColorLightCustom(item.secondary.light);
                          setSecondadryColorDarkCustom(item.secondary.dark);
                          setEditId(item.id);
                          setEditDefaultStatus(item.custom.defaultP);
                        }}
                      >
                        {t("edit")}
                      </Button>
                      <Grid item xs={12}>
                        {item?.id === activeByDate?.id &&
                        item?.custom?.custom ? (
                          <Chip
                            icon={<AccessTimeIcon />}
                            color={"primary"}
                            label={t("activeByDate")}
                            sx={{ mt: 3 }}
                          />
                        ) : item.custom.defaultP ? (
                          <Chip
                            icon={<PushPinRoundedIcon />}
                            color={"primary"}
                            label={t("standardPalette")}
                            sx={{ mt: 3 }}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  )
                );
              })}
            </Grid>
          </>
        ) : (
          <Grid item xs={11} className={styles.dataContainer}>
            <TableContainer className={styles.tableContainer}>
              <Table>
                <TableHead sx={{ backgroundColor: "primary.main" }}>
                  <TableCell
                    sx={{
                      color: textColor,
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      fontFamily: "Roboto, sans-Serif",
                    }}
                  >
                    {t("colors")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: textColor,
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      fontFamily: "Roboto, sans-Serif",
                    }}
                  >
                    {t("name")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: textColor,
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      fontFamily: "Roboto, sans-Serif",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      color: textColor,
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      fontFamily: "Roboto, sans-Serif",
                    }}
                  >
                    {t("type")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: textColor,
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      fontFamily: "Roboto, sans-Serif",
                    }}
                  >
                    {t("effectiveDate")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: textColor,
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      fontFamily: "Roboto, sans-Serif",
                    }}
                  >
                    {t("endOfEffectiveDate")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: textColor,
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      fontFamily: "Roboto, sans-Serif",
                    }}
                  >
                    {t("select")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: textColor,
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      fontFamily: "Roboto, sans-Serif",
                    }}
                  >
                    {t("edit")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: textColor,
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      fontFamily: "Roboto, sans-Serif",
                    }}
                  >
                    {t("delete")}
                  </TableCell>
                </TableHead>
                <TableBody>
                  {Themes?.map((l, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        border:
                          l.displayName === uiConfig.title
                            ? "3px solid"
                            : "none",
                        borderColor: "secondary.main",
                        "&:hover": { backgroundColor: "secondary.light" },
                      }}
                    >
                      <TableCell
                        sx={{
                          ...cellStyles,
                          backgroundColor:
                            i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              backgroundColor: l.primary.normal,
                              width: "50%",
                              minHeight: "40px",
                              display: "flex",
                              color: "transparent",
                            }}
                          >
                            .
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: l.secondary.normal,
                              width: "50%",
                              minHeight: "40px",
                              display: "flex",
                              color: "transparent",
                            }}
                          >
                            .
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={{
                          ...cellStyles,
                          backgroundColor:
                            i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12",
                        }}
                      >
                        {l.displayName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...cellStyles,
                          backgroundColor:
                            i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12",
                        }}
                      >
                        {l?.id === activeByDate?.id && l?.custom?.custom ? (
                          <Chip
                            icon={<AccessTimeIcon />}
                            color={"primary"}
                            label={t("activeByDate")}
                            sx={{ my: 1 }}
                          />
                        ) : l.custom.defaultP ? (
                          <Chip
                            icon={<PushPinRoundedIcon />}
                            color={"primary"}
                            label={t("standardPalette")}
                            sx={{ my: 1 }}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                          backgroundColor:
                            i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12",
                        }}
                      >
                        {l.custom?.custom ? "Personalizado" : "Predefinido"}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                          backgroundColor:
                            i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12",
                        }}
                      >
                        {convertDate(l.custom?.validFrom) || "-"}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                          backgroundColor:
                            i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12",
                        }}
                      >
                        {convertDate(l.custom?.validTo) || "-"}
                      </TableCell>
                      {l.custom.custom ? (
                        <TableCell
                          align="center"
                          sx={{
                            ...cellStyles,
                            backgroundColor:
                              i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12",
                          }}
                        ></TableCell>
                      ) : (
                        <TableCell
                          align="center"
                          sx={{
                            ...cellStyles,
                            backgroundColor:
                              i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12",
                          }}
                        >
                          {l.displayName === uiConfig.title ||
                          l.custom.defaultP ? (
                            <CheckCircleRoundedIcon
                              sx={{ color: "gray", fontSize: "1.8rem" }}
                            />
                          ) : (
                            <Tooltip
                              placement="left"
                              title={t("selectDefault")}
                            >
                              <CheckCircleRoundedIcon
                                color="primary"
                                className={styles.iconbtn}
                                onClick={() => selectDefaultTheme(l, i)}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        sx={{
                          ...cellStyles,
                          backgroundColor:
                            i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12",
                        }}
                      >
                        {!l.custom?.custom ? (
                          <EditRoundedIcon
                            sx={{ color: "gray", fontSize: "1.8rem" }}
                          />
                        ) : (
                          <EditRoundedIcon
                            color="primary"
                            className={styles.iconbtn}
                            onClick={() => {
                              setOpenModal(true);
                              setPaletteName(l.displayName);
                              setFromDate(l.custom.validFrom);
                              setToDate(l.custom.validTo);
                              setPrimaryColorCustom(l.primary.normal);
                              setPrimaryColorLightCustom(l.primary.light);
                              setPrimaryColoDarkrCustom(l.primary.dark);
                              setSecondadryColorCustom(l.secondary.normal);
                              setSecondadryColorLightCustom(l.secondary.light);
                              setSecondadryColorDarkCustom(l.secondary.dark);
                              setEditId(l.id);
                              setEditDefaultStatus(l.custom.defaultP);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...cellStyles,
                          backgroundColor:
                            i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12",
                        }}
                      >
                        {!l.custom?.custom ? (
                          <DeleteForeverOutlinedIcon
                            sx={{ color: "gray", fontSize: "1.8rem" }}
                          />
                        ) : (
                          <DeleteForeverOutlinedIcon
                            color="primary"
                            className={styles.iconbtn}
                            onClick={() => deleteSelectedTheme(l)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )
      ) : (
        <Grid
          item
          xs={11}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeigth: "200px",
          }}
        >
          <CircularProgress size={"3rem"} />
        </Grid>
      )}
      {/*<div className={styles.save}>*/}
      {/*  <Button*/}
      {/*    variant="contained"*/}
      {/*    sx={{ width: "100%", height: 40, mb: 10, mt: 10 }}*/}
      {/*    onClick={() => {*/}
      {/*      setOpenModal(true)*/}
      {/*      setPaletteName("")*/}
      {/*      setFromDate("")*/}
      {/*      setToDate("")*/}
      {/*    }*/}
      {/*    }*/}
      {/*  >*/}
      {/*    {t('addCustomPalette')}*/}
      {/*  </Button>*/}
      {/*</div>*/}

      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        fullWidth
        className={styles.dialogContainer}
      >
        <DialogTitle id="modal-title">
          {editId !== null ? t("editCustomPalette") : t("addCustomPalette")}
        </DialogTitle>
        <DialogContent className={styles.overflow}>
          <Grid container className={styles.paletteTitle}>
            <Grid xs={12} item className={styles.titleInput}>
              <TextField
                onChange={(e) => setPaletteName(e.target.value)}
                variant="outlined"
                sx={{ marginTop: 3, width: "60%", mb: 2 }}
                id="titulo"
                label={editId !== null ? t("title") : t("title") + "*"}
                inputProps={{ maxLength: 23 }}
                value={paletteName}
              />
            </Grid>
            <Grid item xs={12} className={styles.datesContainer}>
              <h3>{t("validity")}</h3>
              <Grid container className={styles.datesInputs}>
                <Grid xs={6} item className={styles.dateInput}>
                  <p>{editId !== null ? t("from") : t("from") + "*"}</p>
                  <input
                    onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate ? formatDate(fromDate) : null}
                    type="date"
                    min={today}
                    max={toDate}
                    className={styles.selectDate}
                  />
                </Grid>
                <Grid item xs={6} className={styles.dateInput}>
                  <p>{editId !== null ? t("to") : t("to") + "*"}</p>
                  <input
                    onChange={(e) => setToDate(e.target.value)}
                    value={toDate ? formatDate(toDate) : null}
                    type="date"
                    min={fromDate}
                    className={styles.selectDate}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <h3>{t("primaryColor")}</h3>
          <Grid sx={{ mb: 5 }} container spacing={2}>
            <Grid item xs={4}>
              <h5>{t("main")}</h5>
              <MuiColorInput
                value={primaryColorCustom}
                onChange={(e) => setPrimaryColorCustom(e)}
                format="hex"
              />
            </Grid>
            <Grid item xs={4}>
              <h5>{t("light")}</h5>
              <MuiColorInput
                value={primaryColorLightCustom}
                onChange={(e) => setPrimaryColorLightCustom(e)}
                format="hex"
              />
            </Grid>
            <Grid item xs={4}>
              <h5>{t("dark")}</h5>
              <MuiColorInput
                value={primaryColorDarkCustom}
                onChange={(e) => setPrimaryColoDarkrCustom(e)}
                format="hex"
              />
            </Grid>
          </Grid>
          <h3 style={{ margin: 0, fontSize: "1rem" }}>{t("secondaryColor")}</h3>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <h5>{t("main")}</h5>
              <MuiColorInput
                value={secondaryColorCustom}
                onChange={(e) => setSecondadryColorCustom(e)}
                format="hex"
              />
            </Grid>
            <Grid item xs={4}>
              <h5>{t("light")}</h5>
              <MuiColorInput
                value={secondaryColorLightCustom}
                onChange={(e) => setSecondadryColorLightCustom(e)}
                format="hex"
              />
            </Grid>
            <Grid item xs={4}>
              <h5>{t("dark")}</h5>
              <MuiColorInput
                value={secondaryColorDarkCustom}
                onChange={(e) => setSecondadryColorDarkCustom(e)}
                format="hex"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              !paletteName.trim().length || !fromDate.length || !toDate.length || !primaryColorCustom.length
              || !primaryColorLightCustom.length || !primaryColorDarkCustom.length || !secondaryColorCustom.length
              || !secondaryColorLightCustom.length || !secondaryColorDarkCustom.length
            }
            variant="contained"
            onClick={editId !== null ? handleEditTheme : addCustomPalette}
            color={"success"}
          >
            {t("accept")}
          </Button>
          <Button variant="contained" color="error" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
