import apiClient from "../../utils/api.client";
import { getSaaId } from "../../utils/session.utils";

export const getCashStatus = async (organizationId, agencia, subagencia) => {
  const saaId = getSaaId();

  try {
    const response = await apiClient.get(
      `/saaServices/saaServices/services/abaco/resumenCaja?organizacionId=${saaId}&agencia=${agencia}&subagencia=${subagencia !== undefined ? subagencia : -1}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at getCashStatus EP: ", error);
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error("Error at getCashStatus EP: ", error);
    }
  }
};
