import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  TextField,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "../index.module.css";
import { useSelector } from "react-redux";
import s from "./OrdersModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MovementsByImputation } from "../../../../api/accounting/accountingHelper";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { RegexAgenciaNumero } from "../../../../config/strings";
import "dayjs/locale/es";
import "dayjs/locale/pt";
import Swal from "sweetalert2";
import { primeroDeOctubre } from "../../../../helpers/today";

const ModalProviders = ({
  open,
  setOpen,
  filters,
  setFilters,
  setSearching,
  setSearch,
  tabValue,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  setRangoDias,
  rangoDias,
}) => {
  const { imputations } = useSelector((state) => state.gamma);
  const { t, i18n } = useTranslation();
  const [wrongDifDate, setWrongDifDate] = useState(false);
  const { homologated_Games } = useSelector((state) => state.gamma);
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult,
  );

  const handleChangeDateFrom = (date_picked) => {
    console.log("dateFrom_picked", date_picked);
    setDateFrom(date_picked);
    setFilters((prevState) => ({
      ...prevState,
      from: `${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}/${
        date_picked.$M + 1 < 10 ? "0" : ""
      }${date_picked.$M + 1}/${date_picked.$y}`,
    }));
    //	console.log("fecha from: ", dateFrom)
  };
  const handleChangeDateTo = (date_picked) => {
    console.log("dateTo_picked", date_picked);
    setDateTo(date_picked);
    setFilters((prevState) => ({
      ...prevState,
      to: `${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}/${
        date_picked.$M + 1 < 10 ? "0" : ""
      }${date_picked.$M + 1}/${date_picked.$y}`,
    }));
  };

  useEffect(() => {
    const dateFromFormat = JSON.stringify(dateFrom)
      .substring(0, 11)
      .split('"')[1];
    const dateToFormat = JSON.stringify(dateTo).substring(0, 11).split('"')[1];
    console.log("FORMATO DE LAS FECHAS: ", dateFromFormat, dateToFormat);

    if (dateFrom && dateTo) {
      let diff = calcularDiferenciaFechas(dateFromFormat, dateToFormat);
      if (diff > 91) {
        setWrongDifDate(true);
        Swal.fire({
          title: t("wrongDates"),
          icon: "error",
        });
      } else {
        setWrongDifDate(false);
      }
    }
  }, [dateFrom, dateTo]);

  const calcularDiferenciaFechas = (fecha1, fecha2) => {
    console.log("Fechas que me llegan: ", fecha1, fecha2);
    // Convertir las cadenas de fecha en objetos Date
    const fecha1Arr = fecha1.split("-").reverse();
    console.log("fecha1Arr ", fecha1Arr);
    const fecha1Obj = new Date(fecha1Arr[2], fecha1Arr[1] - 1, fecha1Arr[0]);
    const fecha2Arr = fecha2.split("-").reverse();
    console.log("fecha2Arr ", fecha2Arr);
    const fecha2Obj = new Date(fecha2Arr[2], fecha2Arr[1] - 1, fecha2Arr[0]);

    // Calcular la diferencia en milisegundos
    const diferenciaMs = Math.abs(fecha2Obj - fecha1Obj);

    // Convertir la diferencia a días
    const dias = Math.ceil(diferenciaMs / (1000 * 60 * 60 * 24));
    setRangoDias(dias);
    console.log("Diferencia: ", rangoDias);
    return dias;
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid container>
        <Grid
          xs={12}
          md={8}
          lg={4.5}
          item
          container
          className={s.boxModal}
          sx={{ bgcolor: "#EEEEEE" }}
        >
          <Grid item xs={10} className={s.exit}>
            <div className={s.titleModal}>
              <p>{t("filter")}</p>
              <span>{t("filterConfig")}</span>
            </div>
            <CloseIcon
              onClick={() => setOpen(false)}
              className={s.exitIcon}
              sx={{ color: "primary.dark" }}
            />
          </Grid>

          <Grid container spacing={1} className={s.filters}>
            <Grid sx={{ fontSize: "1rem", marginBottom: 2 }} item xs={10}>
              {t(tabValue)}
            </Grid>
            <Grid
              item
              container
              xs={10}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item xs={5.5}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                  className={styles.superdate}
                >
                  <DatePicker
                    label={t("from")}
                    value={dateFrom}
                    inputFormat="DD/MM/YYYY"
                    inputProps={{ readOnly: true }}
                    onChange={(date) => handleChangeDateFrom(date)}
                    renderInput={(params) => <TextField {...params} />}
                    className={styles.date}
                    maxDate={dateTo}
                    minDate={primeroDeOctubre()}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={5.5}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                  className={styles.superdate}
                >
                  <DatePicker
                    label={t("to")}
                    inputFormat="DD/MM/YYYY"
                    value={dateTo}
                    inputProps={{ readOnly: true }}
                    onChange={(date) => handleChangeDateTo(date)}
                    renderInput={(params) => <TextField {...params} />}
                    className={styles.date}
                    minDate={dateFrom}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: "30px",
              cursor: "not-allowed",
            }}
          >
            <Button
              disabled={
                (filters.agencieName === "" && filters.agencieNumber === "") ||
                agencieSelected === {} ||
                wrongDifDate
              }
              variant="contained"
              onClick={() => {
                setOpen(false);
                setSearching(true);
                setSearch(true);
              }}
            >
              {t("apply")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalProviders;
