import React, { useEffect, useState } from "react";
import s from "./Apuestas.module.css";
import Grid from "@mui/material/Grid";

import {
  Typography,
  Modal,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CloseIcon from "@mui/icons-material/Close";

//translation
import { useTranslation } from "react-i18next";

import {
  currencyFormat,
  Currency,
} from "../../../../../../helpers/currencyConvert";
import CircularProgress from "@mui/material/CircularProgress";
import { configDate } from "../../../../../../helpers/convertDate";

const ModalListadoPlano = ({
  openListadoPlano,
  handleListadoPlano,
  data,
  listadoPlano,
}) => {
  const [coupons, setCoupons] = useState();
  const [loading, setLoading] = useState(false);
  const [dataListado, setDataListado] = useState();
  const { formatLocalDate, formatLocalDateTime } = configDate();
  const { t } = useTranslation();
  const { formatCurrency } = currencyFormat();

  console.log("------------------");
  console.log(data);
  console.log("------------------");

  console.log("------------------");
  console.log(
    data?.groups?.map((game) => {
      game.map((pdv) => {
        pdv.map((estatus) => {
          estatus.map((item) => item);
        });
      });
    }),
  );
  console.log("------------------");

  return (
    <Modal open={openListadoPlano}>
      <Box className={s.boxModal} sx={{ bgcolor: "#EEEEEE", width: "80vw" }}>
        <div className={s.exit}>
          <div className={s.titleModal}>
            <InfoRoundedIcon
              sx={{ color: "rgb(91, 150, 233)" }}
              className={s.icon}
            />
            <p>{`Detalle`}</p>
          </div>
          <CloseIcon
            onClick={handleListadoPlano}
            className={s.exitIcon}
            sx={{ color: "primary.dark" }}
          />
        </div>
        {loading ? (
          <Grid
            item
            xs={11}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
            }}
          >
            {" "}
            <CircularProgress sx={{ fontSize: "3rem" }} />{" "}
          </Grid>
        ) : (
          <TableContainer>
            <Table className={`print-table`}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("salePoint")}</TableCell>
                  <TableCell>{t("game")}</TableCell>
                  <TableCell>{t("machine")}</TableCell>
                  <TableCell>{t("coupon")}</TableCell>
                  <TableCell>{t("raffles")} </TableCell>
                  <TableCell>{t("status")}</TableCell>
                  <TableCell>{t("caducity")}</TableCell>
                  <TableCell>{t("prizes")} ($)</TableCell>
                  <TableCell>{t("retention")} ($)</TableCell>
                  <TableCell>{t("payer")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listadoPlano.map((item) => (
                  <TableRow>
                    <TableCell className="alinear-derecha">
                      {item.puntoDeVenta || "-"}
                    </TableCell>
                    <TableCell className="alinear-derecha">
                      {item.juegoDescripcion || "-"}
                    </TableCell>
                    <TableCell>{item?.codigoMaquina || "-"}</TableCell>
                    <TableCell className="alinear-derecha">
                      {item.numeroCupon || "-"}
                    </TableCell>
                    <TableCell className="alinear-derecha">
                      {item.numeroSorteo || "-"}
                    </TableCell>
                    <TableCell className="alinear-derecha">
                      {item.estado || "-"}
                    </TableCell>
                    <TableCell className="alinear-centro">
                      {item.fechaCaducidad}
                    </TableCell>
                    <TableCell className="alinear-derecha" align="right">
                      {formatCurrency(item?.premio)}
                    </TableCell>
                    <TableCell className="alinear-derecha" align="right">
                      {formatCurrency(item?.retenido)}
                    </TableCell>
                    <TableCell className="alinear-derecha">
                      {item?.pdvPagador || "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Modal>
  );
};

export default ModalListadoPlano;
