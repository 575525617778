import React from "react";
import { Modal as ModalMaterialUi, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { SearchBar } from "../SearchBar/SearchBar.jsx";
import s from "./ModalShared.module.css";
import { t } from "i18next";

export const Modal = (props) => {
  const {
    icon,
    open,
    onOpen,
    onClose,
    title,
    text,
    keys,
    result,
    onSelect,
    primaryColor,
    placeholder,
    sxStyles,
    component,
    format,
  } = props;

  return (
    <div className={s.container}>
      <IconButton variant="outlined" onClick={onOpen} sx={sxStyles}>
        {icon}
      </IconButton>
      <ModalMaterialUi open={open} onClose={onClose}>
        <Box className={s.boxModal} sx={{ bgcolor: "#EEEEEE" }}>
          <div className={s.title}>
            <p>{title}</p>
          </div>
          <div className={s.body}>
            <div className={s.searchBar}>
              <SearchBar
                keys={keys}
                result={result}
                onSelect={onSelect}
                primaryColor={primaryColor}
                placeholder={placeholder}
                component={component}
                format={format}
              />
            </div>
            <div className={s.content}>{text}</div>
          </div>
        </Box>
      </ModalMaterialUi>
    </div>
  );
};
