import apiClient from "../../utils/api.client";

export const getMPDetallePagos = async (
  inst,
  agencias,
  rango_dias,
  fecha_desde,
  fecha_hasta,
) => {
  try {
    const response = await apiClient.get(
      `/apiMercadoPago/api/consultarDetallePagos/abaco?inst=${inst}&agencias=${agencias}&fecha_desde=${fecha_desde + "T00:00:00"}&fecha_hasta=${fecha_hasta + "T23:59:59"}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("ERROR FETCH TOTAL MP: ", error);
    const respuesta = [];
    return respuesta;
  }
};

export const getMPTotalPagos = async (
  inst,
  agencias,
  rango_dias,
  fecha_desde,
  fecha_hasta,
) => {
  try {
    const response = await apiClient.get(
      `/apiMercadoPago/api/consultarTotalPagos/abaco?inst=${inst}&agencias=${agencias}&fecha_desde=${fecha_desde + "T00:00:00"}&fecha_hasta=${fecha_hasta + "T23:59:59"}`,
    );
    const data = response.data;

    console.log("Respuestax MP: ", data);
    return data;
  } catch (error) {
    console.log("ERROR FETCH TOTAL MP: ", error);
    const respuesta = [];
    return respuesta;
  }
};
