import apiClient from "../../utils/api.client";

export const getTresury = async (
  agencie,
  dateFrom,
  dateTo,
  kind,
  page,
  perPage,
) => {
  try {
    const response = await apiClient.get(
      `/gamma-movements/treasury/agencies/${agencie}/executedObligations?pageNumber=${page}&pageSize=${perPage}&orderCriteria=ASC&orderField=id&fromCreated=${dateFrom}&toCreated=${dateTo}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at getTreasury: ", error);
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error("Error at getTreasury: ", error);
    }
  }
};
