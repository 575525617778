import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  PointsActualPage: 0,
  loadingPoints: false,
  searchedUsersState: false,
  ConfigActualPage: 0,
  Term_and_ConditionActualPage: 0,
  Term_and_ConditionTotalElements: 0,
  LogoActualPage: 0,
  ColorsActualPage: 0,
  currentAccountActualPage: 1,
  UsersActualPage: 0,
  loadingUsers: false,
  loadingTerm: false,
  CuentaCorrienteActualPage: 0,
};

const localSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    UsersNextPage(state, action) {
      state.UsersActualPage = state.UsersActualPage + 1;
    },
    UsersPrevPage(state, action) {
      state.UsersActualPage = state.UsersActualPage - 1;
    },
    UsersChangePage(state, action) {
      state.UsersActualPage = action.payload;
    },
    CuentaCorrienteNextPage(state, action) {
      state.CuentaCorrienteActualPage = state.CuentaCorrienteActualPage + 1;
    },
    CuentaCorrientePrevPage(state, action) {
      state.CuentaCorrienteActualPage = state.CuentaCorrienteActualPage - 1;
    },
    CuentaCorrienteChangePage(state, action) {
      state.CuentaCorrienteActualPage = action.payload;
    },
    loadingUsersPage(state, action) {
      state.loadingUsers = action.payload;
    },
    setSearchUsersPageState(state, action) {
      state.searchedUsersState = action.payload;
    },
    PointsNextPage(state, action) {
      state.PointsActualPage = state.PointsActualPage + 1;
    },
    PointsPrevPage(state, action) {
      state.PointsActualPage = state.PointsActualPage - 1;
    },
    PointsChangePage(state, action) {
      state.PointsActualPage = action.payload;
    },
    loadingPointsPage(state, action) {
      state.loadingPoints = action.payload;
    },
    Term_and_ConditionNextPage(state, action) {
      state.Term_and_ConditionActualPage =
        state.Term_and_ConditionActualPage + 1;
    },
    Term_and_ConditionPrevPage(state, action) {
      state.Term_and_ConditionActualPage =
        state.Term_and_ConditionActualPage - 1;
    },
    Term_and_ConditionChangePage(state, action) {
      state.Term_and_ConditionActualPage = action.payload;
    },
    Term_and_ConditionTotalElementsUpdate(state, action) {
      state.Term_and_ConditionTotalElements = action.payload;
    },
    Term_and_ConditionTotalElementsAdd(state) {
      state.Term_and_ConditionTotalElements =
        state.Term_and_ConditionTotalElements + 1;
    },
    Term_and_ConditionTotalElementsSubtract(state) {
      state.Term_and_ConditionTotalElements =
        state.Term_and_ConditionTotalElements - 1;
    },
    loadingTermsPage(state, action) {
      state.loadingTerm = action.payload;
    },
    LogoNextPage(state, action) {
      state.LogoActualPage = state.LogoActualPage + 1;
    },
    LogoPrevPage(state, action) {
      state.LogosActualPage = state.LogoActualPage - 1;
    },
    LogoChangePage(state, action) {
      state.LogoActualPage = action.payload;
    },
    ColorsNextPage(state, action) {
      state.ColorsActualPage = state.ColorsActualPage + 1;
    },
    ColorsPrevPage(state, action) {
      state.ColorsActualPage = state.ColorsActualPage - 1;
    },
    ColorsChangePage(state, action) {
      state.ColorsActualPage = action.payload;
    },
    currentAccountNextPage(state, action) {
      state.ColorsActualPage = state.ColorsActualPage + 1;
    },
    currentAccountPrevPage(state, action) {
      state.ColorsActualPage = state.ColorsActualPage - 1;
    },
    currentAccountChangePage(state, action) {
      state.ColorsActualPage = action.payload;
    },
    resetPaginationSlice(state, action) {
      state.PointsActualPage = 0;
      state.loadingPoints = false;
      state.ConfigActualPage = 0;
      state.Term_and_ConditionActualPage = 0;
      state.Term_and_ConditionTotalElements = 0;
      state.LogoActualPage = 0;
      state.ColorsActualPage = 0;
      state.currentAccountActualPage = 1;
    },
  },
});

export const {
  UsersPrevPage,
  UsersNextPage,
  UsersChangePage,
  loadingUsersPage,
  setSearchUsersPageState,
  PointsNextPage,
  PointsPrevPage,
  PointsChangePage,
  loadingPointsPage,
  Term_and_ConditionNextPage,
  Term_and_ConditionPrevPage,
  Term_and_ConditionChangePage,
  Term_and_ConditionTotalElementsUpdate,
  Term_and_ConditionTotalElementsAdd,
  Term_and_ConditionTotalElementsSubtract,
  loadingTermsPage,
  LogoNextPage,
  LogoPrevPage,
  LogoChangePage,
  ColorsNextPage,
  ColorsPrevPage,
  ColorsChangePage,
  currentAccountNextPage,
  currentAccountPrevPage,
  currentAccountChangePage,
  resetPaginationSlice,
  CuentaCorrienteChangePage,
  CuentaCorrienteNextPage,
  CuentaCorrientePrevPage,
} = localSlice.actions;

export default localSlice.reducer;
