import { useSelector } from "react-redux";

const ConfigsSearcher = () => {
  const configs = useSelector((state) => state.gamma.appConfig);
  const configFinder = (str) => {
    if (configs?.length) {
      const keyValue = configs?.find((obj) => obj.key === str);
      return keyValue?.value || false;
    }
  };
  return { configFinder };
};

export default ConfigsSearcher;
