import { useState } from "react";
import React from "react";
import {
  Grid,
  Modal,
  TextField,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
} from "@mui/material";
import styles from "../ControlCaja.module.css";
import { useSelector } from "react-redux";
import s from "../ModalProviders/OrdersModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";

import "dayjs/locale/es";
import "dayjs/locale/pt";
import { minDateRange } from "../../../../config/strings";
import { primeroDeOctubre } from "../../../../helpers/today";

const ModalProviders = ({
  open,
  setOpen,
  filters,
  setFilters,
  setSearching,
  setSearch,
  date,
  setDate,
}) => {
  const { t, i18n } = useTranslation();
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult,
  );
  const [fecha, setFecha] = useState(new Date());
  const handleChangeDate = (date_picked) => {
    setFecha(date_picked);
    setDate(date_picked);
    setFilters((prevState) => ({
      ...prevState,
      date: `${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}/${
        date_picked.$M + 1 < 10 ? "0" : ""
      }${date_picked.$M + 1}/${date_picked.$y}`,
    }));
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid container>
        <Grid
          xs={12}
          md={8}
          lg={4.5}
          item
          container
          className={s.boxModal}
          sx={{ bgcolor: "#EEEEEE" }}
        >
          <Grid item xs={10} className={s.exit}>
            <div className={s.titleModal}>
              <p>{t("filter")}</p>
              <span>{t("filterConfig")}</span>
            </div>
            <CloseIcon
              onClick={() => setOpen(false)}
              className={s.exitIcon}
              sx={{ color: "primary.dark" }}
            />
          </Grid>

          <Grid item container xs={10} sx={{ mt: 5 }}>
            <Grid item container xs={12}>
              <Grid sx={{ fontSize: "1.2rem", marginBottom: 3 }} item xs={12}>
                {t("date")}
              </Grid>
              <Grid item xs={12} sx={{ mb: 5 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                  className={styles.superdate}
                >
                  <DatePicker
                    label={t("date")}
                    inputFormat="DD/MM/YYYY"
                    value={fecha}
                    onChange={handleChangeDate}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                    minDate={primeroDeOctubre()}
                    maxDate={new Date()}
                    className={styles.date}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: "20px",
              cursor: "not-allowed",
            }}
          >
            <Button
              variant="contained"
              disabled={
                (filters.agencieName === "" && filters.agencieNumber === 0) ||
                agencieSelected === {}
              }
              onClick={() => {
                setOpen(false);
                setSearching(true);
                setSearch(true);
              }}
            >
              {t("apply")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalProviders;
