import React, { useEffect, useState } from "react";
import s from "./Create.module.css";

//Assets
import Swal from "sweetalert2";
import AgencySelectModal from "../../../Shared/AgencySelectModal/AgencySelectModal";
import AgencyModalAdmin from "../../../Shared/AgencyModalAdmin";
import noImage from "../../../../assets/blank-user.jpg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//Service
import { editUser, create_user } from "../../../../api/users/UsersConfig";
import {
  getAgencieById,
  getAgenciesRelationed,
  sendRelationedAgencies,
} from "../../../../api/agencies/AgenciesService";

//Material Ui
import {
  Grid,
  TextField,
  Button,
  Switch,
  Select,
  Menu,
  MenuItem,
  IconButton,
  Chip,
  CircularProgress,
  Snackbar,
  Alert,
  FormControl,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import StoreIcon from "@mui/icons-material/Store";
import AddIcon from "@mui/icons-material/Add";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DeleteIcon from "@mui/icons-material/Delete";

function obtenerValorDeVerdad(objeto) {
  for (var clave in objeto) {
    if (objeto.hasOwnProperty(clave)) {
      if (objeto[clave] === true) {
        return true;
      }
    }
  }
  return false;
}
const getSwalOptions = (t) => {
  return {
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "green",
    cancelButtonColor: "#d33",
    cancelButtonText: t("cancel"),
    confirmButtonText: t("accept"),
  }
};


// ========================== | Create User | ==================================

const CreateUser = (props) => {
  const { create, setCreate, getData } = props

  // Section: Utils Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Section: Redux Hooks
  const roles = useSelector((state) => state.gamma?.roles?.content);
  const currentUser = useSelector((state) => state.gamma.logged_user);
  const { activeOrganization } = useSelector((state) => state.gamma);
  // Section: States Hook
  const [openRol, setOpenRol] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openReminder, setOpenReminder] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [loadingAgencies, setLoadingAgencies] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [swalOptions] = useState(getSwalOptions(t))

  // Section: State Hooks Form
  const [confirmation, setConfirmation] = useState("");
  const [visibility, setVisibility] = useState({
    password: false,
    confirmation: false,
  });
  const [errors, setErrors] = useState({
    firstname: false,
    lastname: false,
    email: false,
    password: false,
  });

  console.log("CREATE");  
  console.log(create);

  const [form, setForm] = useState({
    id: create.editing ? create.data.id : 0,
    username: create.editing ? create.data.username : "",
    enabled: create.editing ? create.data.enabled : true,
    email: create.editing ? create.data.email : undefined,
    firstname: create.editing ? create.data.firstname : "",
    lastname: create.editing ? create.data.lastname : "",
    password: undefined,
    memberships: create.editing
      ? create.data.memberships
      : [
        {
          selected: true,
          organization: {
            id: activeOrganization?.id,
            name: activeOrganization?.name,
          },
          roles: [],
        },
      ],
  });

  // Section: Effects
  useEffect(() => {
    if (create.editing) {
      setLoadingAgencies(true);
      getAgenciesRelationed(create.data.id, activeOrganization?.id)
        .then((res) => {
          let promises = res?.content?.map((id) =>
            getAgencieById(id, dispatch),
          );
          return Promise.all(promises);
        })
        .then((array) => {
          setAgencies(array);
          setLoadingAgencies(false);
        })
        .catch(() => {
          setLoadingAgencies(false);
        });
    }
  }, []);

  useEffect(() => {
    if (form?.password?.length) {
      let lengthError = confirmation?.length < 3 || confirmation?.length > 25;
      if (lengthError || confirmation !== form.password) {
        setErrors((prev) => ({ ...prev, confirmation: true }));
      } else {
        setErrors((prev) => ({ ...prev, confirmation: false }));
      }
    }
  }, [confirmation, form.password]);

  // Section: Handlers
  const handleBack = () => {
    Swal.fire({
      title: t("formExitTitle"),
      text: t("formExitHtml"),
      ...swalOptions,
    }).then((result) => {
      if (result.isConfirmed) {
        setCreate({ status: false, editing: false, data: null });
        getData();
      }
    });
  };

  const handleSubmit = () => {
    if (agencies.length && form.memberships[0]?.roles?.length) {
      Swal.fire({
        title: t("createUser"),
        text: t("confirmeCreateUser"),
        ...swalOptions,
      }).then((result) => {
        if (result.isConfirmed) {
          setLoadingSubmit(true);
          let { id, username, enabled, ...payload } = form;
          create_user(payload)
            .then((res) => {
              let points = agencies.map((ag) => ag.id);
              sendRelationedAgencies(
                res.id,
                activeOrganization?.id,
                points,
              ).then(() => {
                Swal.fire({
                  icon: "success",
                  title: "Felicidades",
                  text: `${t("user")} ${form.firstname} ${form.lastname} ${t("successfullyCreated")}`,
                });
                setLoadingSubmit(false);
                getData();
                setCreate({ status: false, editing: false, data: null });
              });
            })
            .catch((res) => {
              Swal.fire({
                icon: "error",
                text: `${t("error")}: ${t(res.title)}`,
              });
              setLoadingSubmit(false);
            });
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        text: t("mustHaveRoleAndPoint"),
      });
    }
  };

  const handleEdit = () => {
    if (agencies.length && form.memberships[0]?.roles?.length) {
      Swal.fire({
        title: t("AreYouSreToSaveTheChanges"),
        ...swalOptions,
      })
        .then((result) => {
          if (result.isConfirmed) {
            setLoadingSubmit(true);         
            editUser(form).then((res) => {
              let points = agencies.map((ag) => ag.id);
              sendRelationedAgencies(
                res.id,
                activeOrganization?.id,
                points,
              ).then(() => {
                setLoadingSubmit(false);
                Swal.fire({
                  icon: "success",
                  title: `${t("userEditedSuccessful")}`,
                });
                getData();
                setCreate({ status: false, editing: false, data: null });
              });
            })
            .catch((error) => {
              if (form.id === 0){
                Swal.fire({
                  icon: "error",
                  text: `${t("error")}: ${t(error.title)}`,
                });
              } 
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: `${t("error")}: ${t(error.title)}`,
          });
          setLoadingSubmit(false);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: t("mustHaveRoleAndPoint"),
      });
    }
  };

  const handleChangeConfirmation = (e) => {
    setConfirmation(e.target.value);
  };

  const handleChange = (e) => {
    setForm((prev) => (
      {
        ...prev,
        [e.target.name]: e.target.value
      }
    ));

    let lengthError = e.target.value.length < 3 || e.target.value.length > 25;
    if (e.target.name === "firstname" || e.target.name === "lastname") {
      if (lengthError || !/^[a-zA-Z ]*$/.test(e.target.value)) {
        setErrors((prev) => ({ ...prev, [e.target.name]: true }));
      } else {
        setErrors((prev) => ({ ...prev, [e.target.name]: false }));
      }
    } else if (e.target.name === "password") {
      if (
        lengthError ||
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-_#<+¡}{¿>$@$!%*?&])[A-Za-z\d-_#<+¡}{¿>$@$!%*?&]{8,20}$/.test(
          e.target.value,
        )
      ) {
        setErrors((prev) => ({ ...prev, [e.target.name]: true }));
      } else {
        setErrors((prev) => ({ ...prev, [e.target.name]: false }));
      }
    } else if (e.target.name === "email") {
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value,
        )
      ) {
        setErrors((prev) => ({ ...prev, [e.target.name]: true }));
      } else {
        setErrors((prev) => ({ ...prev, [e.target.name]: false }));
      }
    }
  };

  const handleRol = (type, item) => {
    if (type === 1) {
      if (!form.memberships[0].roles.find((rol) => rol.id === item.id)) {
        setForm((prev) => ({
          ...prev,
          memberships: [
            {
              ...prev.memberships[0],
              roles: [...prev.memberships[0].roles, item],
            },
          ],
        }));
      }
    } else if (type === 2) {
      let filtered = form.memberships[0].roles.filter(
        (rol) => rol.id !== item.id,
      );
      setForm((prev) => ({
        ...prev,
        memberships: [
          {
            ...prev.memberships[0],
            roles: filtered,
          },
        ],
      }));
    }
  };

  const handleSelectAgency = (agency) => {
    if (!agencies.find((item) => item.id === agency.id)) {
      setAgencies((prev) => [...prev, agency]);
    }
  };

  const handleUnselectAgency = (agency) => {
    let filtered = agencies.filter((item) => item.id !== agency.id);
    setAgencies(filtered);
  };

  return (
    <Grid container item xs={12} className={s.container}>
      <Grid item xs={12} className={s.header}>
        <Grid item container xs={12} className={s.titleContainer}>
          <Grid
            item
            xs={11}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <ArrowBackIcon
              sx={{ fontSize: "2rem", color: "primary.main", mr: 2 }}
              onClick={handleBack}
              className={s.arrowback}
            />
            {create.editing ? (
              <h4>{`${t("edit")} ${t("user")}`}</h4>
            ) : (
              <h4>{t("createUser")}</h4>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={s.formulario}>
        {create.editing ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 3,
            }}
          >
            <VerifiedUserIcon
              sx={{ fontSize: "2rem", color: "primary.main" }}
            />
            <p
              style={{ fontSize: "1.2rem", marginInline: "5px" }}
            >{`${t("user")} ${t("active")}`}</p>
            <Switch
              checked={form.enabled}
              size="small"
              onChange={() => {
                setOpenReminder(true);
                setForm((prev) => ({ ...prev, enabled: !prev.enabled }));
              }}
            />
          </Grid>
        ) : null}

        <Grid container className={s.datos}>
          <Grid item xs={5} className={s.item}>
            <TextField
              label="Nombre"
              sx={{ width: "100%" }}
              value={form.firstname}
              onChange={handleChange}
              name="firstname"
              error={errors.firstname}
              helperText={
                errors.firstname
                  ? "Por favor ingrese un nombre valido."
                  : "* Obligatorio"
              }
            />
          </Grid>
          <Grid item xs={5} className={s.item}>
            <TextField
              label="Apellido"
              sx={{ width: "100%" }}
              value={form.lastname}
              onChange={handleChange}
              name="lastname"
              error={errors.lastname}
              helperText={
                errors.lastname
                  ? "Por favor ingrese un apellido valido."
                  : "* Obligatorio"
              }
            />
          </Grid>
          <Grid item xs={5} className={s.item}>
            <TextField
              label="Email"
              autoComplete="new-email"
              sx={{ width: "100%" }}
              value={form.email}
              onChange={handleChange}
              name="email"
              error={errors.email}
              helperText={
                errors.email
                  ? "Por favor ingrese un email valido."
                  : "* Obligatorio"
              }
            />
          </Grid>
          <Grid item xs={5} className={s.item}>
            <Select
              value={"dni"}
              label={t("type")}
              disabled={true}
              sx={{ width: "25%", mr: "5%" }}
            >
              <MenuItem value={"dni"}>DNI</MenuItem>
              <MenuItem value={"lc"}>LC</MenuItem>
              <MenuItem value={"le"}>LE</MenuItem>
              <MenuItem value={"ci"}>CI</MenuItem>
            </Select>
            <TextField disabled={true} label="Número" sx={{ width: "70%" }} />
          </Grid>

          {create.editing ? null : (
            <>
              <Grid item xs={5} className={s.item}>
                <TextField
                  sx={{ width: "100%" }}
                  autoComplete="new-password"
                  label={t("password")}
                  value={form.password}
                  onChange={handleChange}
                  name="password"
                  error={errors.password}
                  helperText={
                    errors.password
                      ? "La contraseña tiene que tener almenos 8 caracteres, 1 mayuscula, números y almenos 1 simbolo."
                      : "* Obligatorio"
                  }
                  type={visibility.password ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setVisibility((prev) => ({
                            ...visibility,
                            password: !prev.password,
                          }))
                        }
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {visibility.password ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={5} className={s.item}>
                <TextField
                  sx={{ width: "100%" }}
                  value={confirmation}
                  onChange={handleChangeConfirmation}
                  label={t("repeatPassword")}
                  error={errors.confirmation}
                  helperText={
                    errors.confirmation
                      ? "Las contraseñas tienen que coincidir."
                      : "* Obligatorio"
                  }
                  type={visibility.confirmation ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setVisibility((prev) => ({
                            ...visibility,
                            confirmation: !prev.confirmation,
                          }))
                        }
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {visibility.confirmation ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid container className={s.boxes}>
          <Grid item container xs={3.8} className={s.box}>
            <Grid item xs={12} className={s.title}>
              <SupervisedUserCircleIcon
                sx={{ color: "primary.main", fontSize: "2.5rem" }}
              />
              <p>Roles</p>
              <AddIcon
                sx={{ color: "primary.main", fontSize: "2rem", ml: 1 }}
                className={s.add}
                onClick={(e) => setOpenRol(e.currentTarget)}
              />
              <Menu
                id="basic-menu"
                anchorEl={openRol}
                open={Boolean(openRol)}
                onClose={() => setOpenRol(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {currentUser.memberships[0].roles[0].name ===
                  "Administrador Loteria Abaco"
                  ? roles
                    ?.filter(
                      (item) =>
                        item.name === "Administrador Loteria Abaco" ||
                        item.name === "Usuario Agenciero" ||
                        item.name === "Usuario Concentradora" ||
                        item.name === "Empleado",
                    )
                    ?.map((item) => (
                      <MenuItem
                        onClick={() => {
                          handleRol(1, item);
                          setOpenRol(null);
                        }}
                        key={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    ))
                  : roles
                    ?.filter((item) => item.name === "Usuario Agenciero")
                    ?.map((item, i) => (
                      <MenuItem
                        onClick={() => {
                          handleRol(1, item);
                          setOpenRol(null);
                        }}
                        key={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
              </Menu>
            </Grid>
            <Grid item xs={12} className={s.items}>
              {form.memberships[0]?.roles?.length
                ? form.memberships[0]?.roles?.map((rol, i) => (
                  <Chip
                    className={s.chip}
                    sx={{ margin: 0.2 }}
                    icon={
                      <IconButton onClick={() => handleRol(2, rol)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                    label={rol?.name}
                    key={i}
                  />
                ))
                : t("doesNotHaveRoles")}
            </Grid>
          </Grid>
          <Grid item xs={3.8} className={s.box}>
            <Grid item xs={12} className={s.title}>
              <StoreIcon sx={{ color: "primary.main", fontSize: "2.5rem" }} />
              <p>{t("points")}</p>
              <AddIcon
                sx={{ color: "primary.main", fontSize: "2rem", ml: 1 }}
                className={s.add}
                onClick={() => setOpenModal(true)}
              />
            </Grid>
            <Grid item xs={12} className={s.items}>
              {loadingAgencies ? (
                <CircularProgress sx={{ color: "primary.main" }} size="2rem" />
              ) : agencies?.length ? (
                agencies?.map((agency, i) => (
                  <Chip
                    className={s.chip}
                    sx={{ margin: 0.2 }}
                    icon={
                      <IconButton onClick={() => handleUnselectAgency(agency)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                    label={`${agency?.number}${agency?.subNumber !== null ? ` - ${agency?.subNumber}` : ""}`}
                    key={i}
                  />
                ))
              ) : (
                t("doesNotHavePointsOfSale")
              )}
            </Grid>
          </Grid>
          <Grid item xs={3.8} className={s.box}>
            <Grid item xs={12} className={s.title}>
              <p>{t("profilePicture")}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "5rem",
              }}
            >
              <img
                src={noImage}
                alt="noimage"
                style={{ height: "100%", borderRadius: "50%" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button variant="contained" disabled sx={{ width: "35%" }}>
                {t("add")}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 2,
          }}
        >
          {create.editing ? (
            <Button
              variant="contained"
              onClick={handleEdit}
              sx={{
                fontWeight: 400,
                width: "10rem",
                textTransform: "capitalize",
                fontSize: "1rem",
              }}
              disabled={
                loadingSubmit ||
                obtenerValorDeVerdad(errors) ||
                !(form.firstname && form.lastname && form.email)
              }
            >
              {loadingSubmit ? (
                <CircularProgress size={"1.5rem"} color="primary" />
              ) : (
                t("save")
              )}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                fontWeight: 400,
                width: "10rem",
                textTransform: "capitalize",
                fontSize: "1rem",
              }}
              disabled={
                loadingSubmit ||
                obtenerValorDeVerdad(errors) ||
                !(
                  form.firstname &&
                  form.lastname &&
                  form.password &&
                  form.email
                )
              }
            >
              {loadingSubmit ? (
                <CircularProgress size={"1.5rem"} color="primary" />
              ) : (
                t("add")
              )}
            </Button>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={openReminder}
        autoHideDuration={3000}
        onClose={() => setOpenReminder(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={() => setOpenReminder(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {t("saveReminder")}
        </Alert>
      </Snackbar>

      {openModal ? (
        currentUser.memberships[0].roles[0].name ===
          "Administrador Loteria Abaco" ? (
          <AgencyModalAdmin
            open={openModal}
            setOpen={setOpenModal}
            onSelect={handleSelectAgency}
          />
        ) : (
          <AgencySelectModal
            open={openModal}
            setOpen={setOpenModal}
            onSelect={handleSelectAgency}
          />
        )
      ) : null}
    </Grid>
  );
};

export default CreateUser;
